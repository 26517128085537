import { gql, useQuery } from "@apollo/client";

const GET_PUBLIC_EVENT_BY_ID_QUERY = gql`
  query getEventInfo($eventId: ID!) {
    getEventInfo(id: $eventId) {
      ... on VirtualEventInfo {
        id
        title
      }
      ... on EventInfo {
        id
        title
        canViewPreciseLocation
        lat
        lng
        addressString
      }
    }
  }
`;

export type EventInfoDTO = {
  id: string;
  title: string;
  canViewPreciseLocation?: boolean;
  lat?: number;
  lng?: number;
  addressString?: string;
};

type Response = {
  getEventInfo: EventInfoDTO;
};

type Input = {
  eventId: string;
};
export function useEventInfoById(id?: string) {
  const { data, loading } = useQuery<Response, Input>(
    GET_PUBLIC_EVENT_BY_ID_QUERY,
    {
      skip: !id,
      variables: {
        eventId: id as string,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  return {
    loading,
    event: data?.getEventInfo,
  };
}
