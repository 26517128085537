import { useCheckoutSessionRemainingTime } from "./useCheckoutSessionRemainingTime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCheckoutSessionCtx } from "./useCheckoutSessionCtx";

function getMinAndSecondsFromSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  return { minutes, seconds: secondsLeft };
}

function CheckoutSessionRemainingTimeInner({
  initialRemainingTimeInSeconds,
}: {
  initialRemainingTimeInSeconds: number;
}) {
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState(
    initialRemainingTimeInSeconds
  );
  const { t } = useTranslation();
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTimeInSeconds((v) => (v <= 0 ? 0 : v - 1));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const { minutes, seconds } = getMinAndSecondsFromSeconds(
    remainingTimeInSeconds
  );

  return (
    <p className={"bg-yellow-100 p-6 rounded-md flex items-center"}>
      {remainingTimeInSeconds > 0
        ? t("checkout.completeBefore", {
            minutes,
            seconds,
          })
        : t("checkout.sessionExpired")}
    </p>
  );
}
export function CheckoutSessionTimer() {
  const { sessionId } = useCheckoutSessionCtx();
  const { remainingTimeInSeconds, loading } =
    useCheckoutSessionRemainingTime(sessionId);
  if (loading) return null;
  return (
    <CheckoutSessionRemainingTimeInner
      initialRemainingTimeInSeconds={remainingTimeInSeconds}
    />
  );
}
