import { gql, useMutation } from "@apollo/client";
import { GET_PM } from "./usePaymentMethods";
import { useCallback } from "react";

const ADD_PM = gql`
  mutation addPaymentMethod($pmId: String!) {
    addPaymentMethod(paymentMethodId: $pmId) {
      created
      platformPaymentMethodId
    }
  }
`;

type AddPmResponse = {
  addPaymentMethod: {
    created: boolean;
    platformPaymentMethodId: string;
  };
};

type AddPmInput = {
  pmId: string;
};
export function useAddPaymentMethod() {
  const [addPaymentMethodMutation, { loading }] = useMutation<
    AddPmResponse,
    AddPmInput
  >(ADD_PM, {
    refetchQueries: [GET_PM],
  });

  const addPaymentMethod = useCallback(
    async (pmId: string): Promise<string> => {
      const { data } = await addPaymentMethodMutation({
        variables: {
          pmId,
        },
      });
      const platformPmId = data?.addPaymentMethod?.platformPaymentMethodId;
      if (!platformPmId) throw new Error("No payment method id returned");
      return platformPmId;
    },
    [addPaymentMethodMutation]
  );
  return {
    addPaymentMethod,
    loading,
  };
}
