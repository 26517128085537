import { SquaredCard } from "../UI/SquaredCard";
import QRCode from "react-qr-code";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { EventOrderDTO } from "./EventOrderDTO";
import { useRef } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { InfoFeedback } from "../UI/Feedback/InfoFeedback";

type Props = {
  order: EventOrderDTO;
};
export function OrderQrCodeCard({ order }: Props) {
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  function triggerDownload(imgURI: string) {
    const evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    });

    const a = document.createElement("a");
    a.setAttribute(
      "download",
      `${order?.eventTitle ?? "no-title"}-${dayjs(
        order?.eventDate
      ).toISOString()}.png`
    );
    a.setAttribute("href", imgURI);
    a.setAttribute("target", "_blank");

    a.dispatchEvent(evt);
  }
  function downloadQrCode() {
    if (!qrCodeRef.current) {
      return;
    }
    const svg = qrCodeRef.current
      .getElementsByTagName("svg")?.[0]
      ?.cloneNode(true) as SVGSVGElement;
    svg.width.baseVal.value = 500;
    svg.height.baseVal.value = 500;
    if (!svg) {
      console.error(`svg is null`);
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.height = svg.height.baseVal.value;
    canvas.width = svg.width.baseVal.value;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error(`Ctx is null`);
      return;
    }

    const data = new XMLSerializer().serializeToString(svg);
    const DOMURL = window.URL || window.webkitURL || window;

    const img = new Image();
    const svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
    const url = DOMURL.createObjectURL(svgBlob);

    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      triggerDownload(imgURI);
    };

    img.src = url;
  }

  function getValidForString(): string {
    return order?.rates
      ?.map((r) => `${r.quantity}x ${r.rate.name}`)
      .join("<br/>");
  }
  return (
    <SquaredCard>
      <h4 className={"font-bold"}>{t("orderQrCode.title")}</h4>
      <p
        dangerouslySetInnerHTML={{
          __html: t("orderQrCode.desc"),
        }}
      />
      <div className={"flex flex-col items-center mt-4"}>
        <div ref={qrCodeRef}>
          <QRCode
            size={100}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${order.qrCodeData}`}
            viewBox={`0 0 100 100`}
          />
        </div>
        <div className={"mt-4"}>
          <PrimarySolidButton
            onClick={downloadQrCode}
            label={t("orderQrCode.download")}
          />
        </div>
        <div className={"mt-4 w-full"}>
          <InfoFeedback
            description={t("orderQrCode.validFor", {
              validForString: getValidForString(),
            })}
          />
        </div>
      </div>
    </SquaredCard>
  );
}
