import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const DELETE_ACCOUNT_MUTATION = gql`
  mutation deleteAccount {
    deleteAccount
  }
`;

type Response = {
  deleteAccount: boolean;
};
export function useDeleteAccount() {
  const [deleteAccountMutation, { loading }] = useMutation<Response>(
    DELETE_ACCOUNT_MUTATION
  );
  const deleteAccount = useCallback(async () => {
    const { data } = await deleteAccountMutation();
    return data?.deleteAccount;
  }, [deleteAccountMutation]);

  return {
    loading,
    deleteAccount,
  };
}
