import dayjs from "dayjs";
import { useLang } from "../I18n/useLang";
import { useTranslation } from "react-i18next";

export function EventDateRenderer({
  date,
  className,
}: {
  date: Date;
  className?: string;
}) {
  const { lang } = useLang();
  const { t } = useTranslation();
  return (
    <p className={["text-slate-500", className].join(" ")}>
      {dayjs(date).locale(lang).format(t("eventDate.formatString"))}
    </p>
  );
}
