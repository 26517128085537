import { Layout } from "../../UI/Layout";
import { Card } from "../../UI/Card";
import QRCode from "react-qr-code";
import { FullScreenLoading } from "../../UI/FullScreenLoading";
import { useQrCodeById } from "./useQrCodeById";
import { useQrCodeIdOrThrow } from "./useQrCodeIdOrThrow";
import { Footer } from "../../UI/Footer";
import { Header } from "../../UI/Header";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const QrCodeV2ScreenPath = "/qr-code/v2/:qrCodeId";
export function QrCodeV2Screen() {
  const { qrCodeId } = useQrCodeIdOrThrow();
  const { data, loading, eventTitle, eventDate, userFullName, eventEndDate } =
    useQrCodeById(qrCodeId);
  const { i18n } = useTranslation();
  if (loading) return <FullScreenLoading />;
  const dateFormat = "dddd DD MMMM YYYY";
  return (
    <Layout>
      <Header />
      <div className={"flex-1 p-4 w-full"}>
        <Card>
          <div className={"flex flex-col mb-4 space-y-2"}>
            <h2 className={"font-bold text-center text-2xl"}>{eventTitle}</h2>
            <p className={"text-slate-500 text-center"}>
              {dayjs(eventDate).locale(i18n.language).format(dateFormat)} -{" "}
              {dayjs(eventEndDate).locale(i18n.language).format(dateFormat)}
            </p>
          </div>
          <div className={"flex justify-center"}>
            <QRCode
              size={100}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={data}
              viewBox={`0 0 100 100`}
            />
          </div>
          <div>
            <p className={"font-bold text-center mt-4 text-xl"}>
              {userFullName}
            </p>
          </div>
        </Card>
      </div>

      <Footer />
    </Layout>
  );
}
