import React from "react";

export type CheckoutSessionCtxType = {
  sessionId: string;
  deleteSession: () => void;
  setSessionId: (sessionId: string) => void;
};

export const CheckoutSessionCtx = React.createContext<CheckoutSessionCtxType>({
  sessionId: "",
  deleteSession: () => {
    throw new Error("deleteSession not implemented");
  },
  setSessionId: () => {
    throw new Error("setSessionId not implemented");
  },
});
