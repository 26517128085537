import { gql, useQuery } from "@apollo/client";
import { EventRateDTO } from "./EventRateDTO";

const GET_EVENT_RATES_BY_IDS = gql`
  query getEventRatesByIds($rateIds: [String!]!, $eventId: String!) {
    getEventRatesByIds(eventId: $eventId, rateIds: $rateIds) {
      id
      price
      eventId
      description
      name
    }
  }
`;

type Response = {
  getEventRatesByIds: EventRateDTO[];
};

type Input = {
  eventId: string;
  rateIds: string[];
};

export function useEventRatesByIds(eventId?: string, rateIds: string[] = []) {
  const { data, loading } = useQuery<Response, Input>(GET_EVENT_RATES_BY_IDS, {
    variables: {
      eventId: eventId as string,
      rateIds,
    },
    skip: rateIds.length <= 0 || !eventId,
  });

  return {
    rates: data?.getEventRatesByIds ?? [],
    loading,
  };
}
