import { PaymentMethodDto } from "./usePaymentMethods";
import { RadioCircle } from "../UI/RadioCircle";
import { SquaredCard } from "../UI/SquaredCard";

type Props = {
  pm: PaymentMethodDto;
  showCheckBox?: boolean;
  checked?: boolean;
  onSelected?: () => void;
};

export function PaymentMethodComponent(props: Props) {
  return (
    <SquaredCard>
      <div className={"flex items-center"}>
        {props.showCheckBox ? (
          <div className={"mr-2"}>
            <RadioCircle
              checked={props.checked ?? false}
              onChange={() => {
                //void
              }}
            />
          </div>
        ) : null}
        <div
          className={[
            "flex-1",
            props.showCheckBox ? "flex flex-col items-end" : "",
          ].join(" ")}
        >
          <h3 className={"text-gray-800"}>
            **** **** **** {props.pm.last4digit}
          </h3>

          <p className={"text-gray-400 mt-2 text-sm flex  items-center"}>
            <span className={"ml-auto"}>
              {props.pm.expMonth} / {props.pm.expYear}
            </span>
          </p>
        </div>
      </div>
    </SquaredCard>
  );
}
