import { useSearchParams } from "react-router-dom";
import { useEventOrderById } from "./useEventOrderById";
import { Layout } from "../UI/Layout";
import { OrderQrCodeCard } from "./OrderQrCodeCard";
import { OrderSummary } from "./OrderSummary";
import { useEventInfoById } from "../Event/useEventInfoById";
import { EventOrderDTO } from "./EventOrderDTO";
import { FullScreenLoading } from "../UI/FullScreenLoading";
import { EventMap } from "../Event/EventMap";
import { canShowEventMap } from "../Event/canShowEventMap";
import { Header } from "../UI/Header";
import { Footer } from "../UI/Footer";
import { AppStoresDownload } from "./AppStoresDownload";

function EventOrder({ order }: { order: EventOrderDTO }) {
  const { event, loading } = useEventInfoById(order.eventId);

  if (loading) return <FullScreenLoading />;
  if (!event) return null; //TODO not found
  return (
    <Layout>
      <Header />
      <div className={"p-4"}>
        <OrderQrCodeCard order={order} />
        <div className={"mt-4"}>
          <OrderSummary order={order} />
        </div>
        {canShowEventMap(event) ? (
          <div className={"mt-4"}>
            <EventMap eventInfo={event} />
          </div>
        ) : null}
        <div className={"mt-4"}>
          <AppStoresDownload />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

type Props = {
  orderId: string;
};
function EventOrderInner({ orderId }: Props) {
  const { order, loading } = useEventOrderById(orderId);
  if (loading) return <FullScreenLoading />;
  if (!order) return null; //TODO 404
  return <EventOrder order={order} />;
}

export function EventOrderScreen() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("oid");
  if (!orderId) return null; //TODO 404
  return <EventOrderInner orderId={orderId} />;
}
