import { useEffect, useState } from "react";
import { getIdTokenAsync } from "../Firebase/getIdTokenAsync";

export function TokenScreen() {
  const [token, setToken] = useState("");

  useEffect(() => {
    (async function start() {
      const t = await getIdTokenAsync();
      setToken(t ?? "");
    })();
  }, []);

  return (
    <div className={"p-2"}>
      <pre>{token}</pre>
    </div>
  );
}
