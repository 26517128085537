import useSWR from "swr";
import axios from "axios";
import { getIdTokenAsync } from "../../../Firebase/getIdTokenAsync";
import dayjs from "dayjs";
type ScanReportEntryDTO = {
  date: string;
  accessCount: number;
};

type RestEventDTO = {
  id: string;
  date: string;
  title: string;
};

export function useScanReport(eventId: string) {
  const { data, isLoading } = useSWR(`/scan-report/${eventId}`, async () => {
    const { data } = await axios.get<{
      event: RestEventDTO;
      reportEntries: ScanReportEntryDTO[];
    }>(
      `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/event-access/qr-code/v2/events/${eventId}/scan-report`,
      {
        headers: {
          Authorization: await getIdTokenAsync(),
        },
        params: {
          timezone: dayjs.tz.guess(),
          from: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          to: dayjs().add(1, "day").format("YYYY-MM-DD"),
        },
      }
    );

    return data;
  });

  return {
    report: data,
    isLoading,
  };
}
