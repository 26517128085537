import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const CREATE_STRIPE_PAYMENT_INTENT = gql`
  mutation createStripePaymentIntent(
    $eventId: String!
    $platformPaymentMethodId: String!
    $orderId: String!
    $clientSessionId: String!
  ) {
    createStripePaymentIntent(
      eventId: $eventId
      platformPaymentMethodId: $platformPaymentMethodId
      orderId: $orderId
      clientSessionId: $clientSessionId
    ) {
      paymentIntentId
      paymentIntentSecret
      paymentMethodId
    }
  }
`;

type CreatePiResponseDTO = {
  paymentIntentId: string;
  paymentIntentSecret: string;
  paymentMethodId: string;
};

type CreatePiResponse = {
  createStripePaymentIntent: CreatePiResponseDTO;
};

type CreatePiInput = {
  eventId: string;
  platformPaymentMethodId: string;
  orderId: string;
  clientSessionId: string;
};
export function useCreateStripePaymentIntent() {
  const [createPiMutation, { loading }] = useMutation<
    CreatePiResponse,
    CreatePiInput
  >(CREATE_STRIPE_PAYMENT_INTENT);

  const createStripePaymentIntent = useCallback(
    async (
      eventId: string,
      platformPaymentMethodId: string,
      orderId: string,
      clientSessionId: string
    ) => {
      const { data } = await createPiMutation({
        variables: {
          eventId,
          platformPaymentMethodId,
          orderId,
          clientSessionId,
        },
      });

      const res = data?.createStripePaymentIntent;

      if (!res)
        throw new Error(`Something went wrong creating stripe payment intent`);
      return res;
    },
    [createPiMutation]
  );

  return {
    createStripePaymentIntent,
    loading,
  };
}
