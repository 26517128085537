import { useCallback, useState } from "react";
import axios from "axios";
import { getIdTokenAsync } from "../../../Firebase/getIdTokenAsync";

export function useScanQrCodeV2() {
  const [loading, setLoading] = useState(false);
  const scanQrCodeV2 = useCallback(async (qrCodeId: string) => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/event-access/qr-code/v2/${qrCodeId}/scan`,
        {},
        {
          headers: {
            Authorization: await getIdTokenAsync(),
          },
        }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    scanQrCodeV2,
    loading,
  };
}
