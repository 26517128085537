import { EventDTO } from "./EventDTO";

export function useEventFeaturedImage(event: EventDTO) {
  const featuredImg = event.gallery?.[0] ?? {
    src: "",
  };

  return {
    featuredImg,
  };
}
