import { Layout } from "../../../UI/Layout";
import { useScanQrCodeV2 } from "./useScanQrCodeV2";
import { useEffect, useState } from "react";
import { useQrCodeIdOrThrow } from "../useQrCodeIdOrThrow";
import { PrimaryLoadingIndicator } from "../../../UI/PrimaryLoadingIndicator";
import { ErrorFeedback } from "../../../UI/Feedback/ErrorFeedback";
import { InfoFeedback } from "../../../UI/Feedback/InfoFeedback";

export const ScanQrCodeV2ScreenPath = "/qr-code/v2/:qrCodeId/scan";
export function ScanQrCodeV2Screen() {
  const { qrCodeId } = useQrCodeIdOrThrow();
  const { scanQrCodeV2, loading } = useScanQrCodeV2();
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    (async function scan() {
      try {
        await scanQrCodeV2(qrCodeId);
      } catch (e) {
        setHasError(true);
      }
    })();
  }, [scanQrCodeV2, qrCodeId]);

  return (
    <Layout>
      <div className={"p-4 flex flex-col flex-1"}>
        {loading ? (
          <div className={"flex flex-1 justify-center items-center"}>
            <div className={"flex flex-col items-center space-y-2"}>
              <PrimaryLoadingIndicator size={"large"} />
              <p className={"text-center"}>Scanning...</p>
            </div>
          </div>
        ) : (
          <div className={"flex flex-1 items-center justify-center"}>
            {hasError ? (
              <ErrorFeedback
                description={"Ops, questo QR code non sembra valido"}
              />
            ) : (
              <InfoFeedback description={"Qr code scansionato con successo"} />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
