import { gql, useQuery } from "@apollo/client";

export const GET_PM = gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      last4digit
      expMonth
      expYear
    }
  }
`;

export type PaymentMethodDto = {
  last4digit: string;
  id: string;
  expMonth: string;
  expYear: string;
};

type GetPmResponse = {
  getPaymentMethods: PaymentMethodDto[];
};

export function usePaymentMethods() {
  const { data, loading } = useQuery<GetPmResponse>(GET_PM, {});

  return {
    paymentMethods: data?.getPaymentMethods ?? [],
    loading,
  };
}
