import { UserDTO } from "./UserDTO";
import { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { encode } from "base-64";
function ConditionalRedirect({ redirectTo }: { redirectTo?: string }) {
  if (!redirectTo) return null;
  else
    return <Navigate to={`${redirectTo}?r=${encode(window.location.href)}`} />;
}

type Props = PropsWithChildren<{
  user?: UserDTO;
  needToHaveUserInfo?: boolean;
  redirectTo?: string;
}>;
export function ProtectedRoute(props: Props) {
  if (!props.user) return <ConditionalRedirect redirectTo={props.redirectTo} />;

  function haveAllUserInfo(): boolean {
    return !!props.user?.name && !!props.user?.birthDate && !!props.user?.email;
  }
  if (props.needToHaveUserInfo && !haveAllUserInfo())
    return <ConditionalRedirect redirectTo={props.redirectTo} />;
  return <>{props.children ?? null}</>;
}
