import { gql, useQuery } from "@apollo/client";

const GET_REMAINING_TIME = gql`
  query getSessionRemainingTime($sessionId: String!) {
    getSessionRemainingTime(sessionId: $sessionId) {
      remainingTimeInSeconds
    }
  }
`;

type Response = {
  getSessionRemainingTime: {
    remainingTimeInSeconds: number;
  };
};
type Input = {
  sessionId: string;
};
export function useCheckoutSessionRemainingTime(sessionId: string) {
  const { data, loading } = useQuery<Response, Input>(GET_REMAINING_TIME, {
    fetchPolicy: "cache-and-network",
    variables: {
      sessionId,
    },
  });

  return {
    remainingTimeInSeconds:
      data?.getSessionRemainingTime.remainingTimeInSeconds ?? 0,
    loading,
  };
}
