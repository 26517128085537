import { Layout } from "../UI/Layout";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useNavigate } from "react-router-dom";
import { usePaymentMethods } from "./usePaymentMethods";
import { PrimaryLoadingIndicator } from "../UI/PrimaryLoadingIndicator";
import { PaymentMethodComponent } from "./PaymentMethodComponent";
import { useTranslation } from "react-i18next";
import { safeGtag } from "../Analytics/safeGtag";

export function PaymentMethodScreen() {
  const navigate = useNavigate();
  const { paymentMethods, loading } = usePaymentMethods();
  const { t } = useTranslation();
  function toNewPaymentMethod() {
    safeGtag("event", "ToAddPaymentMethod");
    navigate("/add-pm");
  }

  return (
    <Layout>
      <div className={"p-4 flex-1 flex flex-col"}>
        <h1 className={"font-bold text-gray-900 text-2xl text-center"}>
          {t("paymentMethods.title")}
        </h1>

        <div>
          {loading ? (
            <div className={"py-8 px-4 flex justify-center"}>
              <PrimaryLoadingIndicator size={"large"} />
            </div>
          ) : (
            <div className={"flex flex-col space-y-4 mt-4"}>
              {paymentMethods.map((pm) => {
                return <PaymentMethodComponent key={pm.id} pm={pm} />;
              })}
            </div>
          )}
        </div>
        <div className={"mt-auto flex justify-center sticky p-2"}>
          <PrimarySolidButton
            onClick={toNewPaymentMethod}
            label={t("paymentMethods.newPaymentMethodCta")}
          />
        </div>
      </div>
    </Layout>
  );
}
