import xss from "xss";
import { InformationCircleIcon } from "@heroicons/react/outline";
type Props = {
  description: string;
};
export function InfoFeedback({ description }: Props) {
  return (
    <div className={"bg-soft-primary rounded-md w-full p-3 flex items-center"}>
      <InformationCircleIcon className={"w-6 h-6 text-primary mr-3"} />
      <p
        className={"text-primary"}
        dangerouslySetInnerHTML={{
          __html: xss(description),
        }}
      />
    </div>
  );
}
