export function useDownloadQrCode() {
  function triggerDownload(imgURI: string, fileName: string) {
    const evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    });

    const a = document.createElement("a");
    a.setAttribute("download", `${fileName}.png`);
    a.setAttribute("href", imgURI);
    a.setAttribute("target", "_blank");

    a.dispatchEvent(evt);
  }
  function downloadQrCode(qrCodeEl: HTMLDivElement, fileName: string) {
    if (!qrCodeEl) {
      return;
    }
    const svg = qrCodeEl
      .getElementsByTagName("svg")?.[0]
      ?.cloneNode(true) as SVGSVGElement;
    svg.width.baseVal.value = 500;
    svg.height.baseVal.value = 500;
    if (!svg) {
      console.error(`svg is null`);
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.height = svg.height.baseVal.value;
    canvas.width = svg.width.baseVal.value;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error(`Ctx is null`);
      return;
    }

    const data = new XMLSerializer().serializeToString(svg);
    const DOMURL = window.URL || window.webkitURL || window;

    const img = new Image();
    const svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
    const url = DOMURL.createObjectURL(svgBlob);

    img.onload = function () {
      ctx.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      triggerDownload(imgURI, fileName);
    };

    img.src = url;
  }

  return {
    downloadQrCode,
  };
}
