import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useLang } from "../../I18n/useLang";

export function useToJoinedEventScreen() {
  const navigate = useNavigate();

  const { lang } = useLang();

  const getJoinedEventUrl = useCallback(
    (eventId: string) => {
      return `/${lang}/joined-event?eid=${eventId}`;
    },
    [lang]
  );
  const toJoinedEventScreen = useCallback(
    (eventId: string) => {
      return navigate(getJoinedEventUrl(eventId));
    },
    [navigate, getJoinedEventUrl]
  );

  return {
    toJoinedEventScreen,
    getJoinedEventUrl,
  };
}
