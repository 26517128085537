import { gql, useMutation } from "@apollo/client";
import { EventOrderDTO } from "./EventOrderDTO";
import { EventRateToPayInput } from "./EventRateToPayInput";
import { useCallback } from "react";
import { OrderChannels } from "./useOrderChannel";

const CREATE_DRAFT_ORDER = gql`
  mutation createNewDraftEventOrderOrGetOld(
    $eventId: String!
    $eventRatesToPay: [EventRateToPayInput!]!
    $clientSessionId: String!
    $channel: EventOrderChannel!
    $prId: String
  ) {
    createNewDraftEventOrderOrGetOld(
      eventId: $eventId
      eventRatesToPay: $eventRatesToPay
      clientSessionId: $clientSessionId
      channel: $channel
      prId: $prId
    ) {
      order {
        id
        addressString
        eventDate
        eventDescription
        eventTitle
        rates {
          rate {
            price
            name
            description
            id
          }
          quantity
        }
        orderTotal
        justMeetFees
      }
      status
      createdSessionId
    }
  }
`;
export enum CreateDraftOrderOrGetOldGraphqlStatus {
  CreatedNewOrder = "CreatedNewOrder",
  GotOldOrder = "GotOldOrder",
  CreatedNewOrderWithNewSession = "CreatedNewOrderWithNewSession",
}
type Response = {
  createNewDraftEventOrderOrGetOld: {
    order: EventOrderDTO;
    status: CreateDraftOrderOrGetOldGraphqlStatus;
    createdSessionId?: string;
  };
};

type Input = {
  eventId: string;
  eventRatesToPay: EventRateToPayInput[];
  clientSessionId: string;
  channel: OrderChannels;
  prId?: string;
};
export function useCreateDraftOrder() {
  const [createDraftOrderMutation, { loading }] = useMutation<Response, Input>(
    CREATE_DRAFT_ORDER
  );

  const createDraftOrder = useCallback(
    async (
      eventId: string,
      eventRatesToPay: EventRateToPayInput[],
      sessionId: string,
      channel: OrderChannels,
      prId?: string
    ): Promise<
      | {
          hasNewSession: false;
          order: EventOrderDTO;
        }
      | {
          hasNewSession: true;
          newSessionId: string;
          order: EventOrderDTO;
        }
    > => {
      const { data } = await createDraftOrderMutation({
        variables: {
          eventId,
          eventRatesToPay,
          clientSessionId: sessionId,
          channel,
          prId,
        },
      });
      const order = data?.createNewDraftEventOrderOrGetOld?.order;
      if (!order) throw new Error(`Error creating order`);
      const status = data?.createNewDraftEventOrderOrGetOld?.status;
      if (!status) throw new Error(`Error creating order`);
      switch (status) {
        case CreateDraftOrderOrGetOldGraphqlStatus.GotOldOrder:
        case CreateDraftOrderOrGetOldGraphqlStatus.CreatedNewOrder:
          return {
            order: order,
            hasNewSession: false,
          };
        case CreateDraftOrderOrGetOldGraphqlStatus.CreatedNewOrderWithNewSession: {
          if (!data?.createNewDraftEventOrderOrGetOld?.createdSessionId)
            throw new Error(`Error creating order`);
          return {
            order: order,
            hasNewSession: true,
            newSessionId:
              data.createNewDraftEventOrderOrGetOld.createdSessionId,
          };
        }
      }
    },
    [createDraftOrderMutation]
  );

  return {
    loading,
    createDraftOrder,
  };
}
