import { EventInfoDTO } from "./useEventInfoById";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { PrimaryLoadingIndicator } from "../UI/PrimaryLoadingIndicator";
import { useLang } from "../I18n/useLang";
import React from "react";
import { canShowEventMap } from "./canShowEventMap";
import { useTranslation } from "react-i18next";
import { SquaredCard } from "../UI/SquaredCard";

const MemoizedMapComponent = React.memo(function InnerMemoizedMapComponent({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}) {
  return (
    <GoogleMap
      onClick={() => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(url, "_blank", "noopener noreferrer nofollow");
      }}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
      mapContainerClassName={"w-full h-full"}
      center={{
        lat: lat,
        lng: lng,
      }}
      zoom={10}
    >
      <Marker
        position={{
          lat: lat,
          lng: lng,
        }}
      />
    </GoogleMap>
  );
});

export function EventMap({
  eventInfo,
  className,
}: {
  eventInfo: EventInfoDTO;
  className?: string;
}) {
  const { lang } = useLang();
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: lang,
  });
  if (!isLoaded)
    return (
      <div
        className={[
          "flex items-center justify-center p-4",
          className ?? "",
        ].join(" ")}
      >
        <PrimaryLoadingIndicator size={"large"} />
      </div>
    );
  if (!canShowEventMap(eventInfo)) return null;
  return (
    <SquaredCard className={className} noPadding>
      <div className={"w-full relative"}>
        <div className={"p-4"}>
          <h2 className={"text-gray-900 mb-1 font-bold"}>
            {t("eventMap.title")}
          </h2>
          <p className={"text-gray-600"}>{eventInfo.addressString}</p>
        </div>
        <div className={"h-60"}>
          <MemoizedMapComponent
            lat={eventInfo.lat as number}
            lng={eventInfo.lng as number}
          />
        </div>
      </div>
    </SquaredCard>
  );
}
