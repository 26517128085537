import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const JOIN_EVENT_MUTATION = gql`
  mutation joinEvent($eventId: String!) {
    joinEvent(eventId: $eventId) {
      status
    }
  }
`;

export enum JoinEventResponseStatus {
  AccessRequested = "ACCESS_REQUESTED",
  AddedToEvent = "ADDED_TO_EVENT",
}
type Response = {
  joinEvent: {
    status: JoinEventResponseStatus;
  };
};

type Input = {
  eventId: string;
};
export function useJoinEvent() {
  const [joinEventMutation, { loading }] = useMutation<Response, Input>(
    JOIN_EVENT_MUTATION
  );

  const joinEvent = useCallback(
    async (eventId: string): Promise<JoinEventResponseStatus> => {
      const { data } = await joinEventMutation({
        variables: {
          eventId,
        },
      });
      return data?.joinEvent?.status ?? JoinEventResponseStatus.AddedToEvent;
    },
    [joinEventMutation]
  );

  return {
    joinEvent,
    loading,
  };
}
