import { useParams } from "react-router-dom";

export function useQrCodeIdOrThrow() {
  const { qrCodeId } = useParams<{
    qrCodeId: string;
  }>();
  if (!qrCodeId) {
    throw new Error("qrCodeId is required");
  }

  return {
    qrCodeId,
  };
}
