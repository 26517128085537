export const en = {
  paymentMethods: {
    title: "Payment methods",
    newPaymentMethodCta: "Add payment method",
  },
  addPaymentMethod: {
    title: "Add payment method",
    cta: "Add",
    reassurance:
      'Safe payments with <a class="text-blue-600 underline" href="https://stripe.com">Stripe</a>',
  },
  checkout: {
    newPm: "New payment method",
    sessionExpired: "Your session has expired, refresh the page to try again",
    soldOutTitle: "Sold out",
    soldOutExpl: "It looks like what you are looking for is out of stock 😔",
    completeBefore:
      "Complete your order within {{minutes}} minutes and {{seconds}} seconds",
    somethingWentWrongTitle: "Something went wrong",
    somethingWentWrongDescription:
      "An error occurred during the payment, please try again later",
    paymentMethodTitle: "Payment method",
    addPm: "Add payment method",
    pay: "Pay",
    addPaymentMethodErrorTitle: "Add a a payment method",
    addPaymentMethodErrorMessage:
      'To proceed with the purchase you must associate a payment method to your account. You can do this by clicking on "Add a payment method"',
    missingPmErrorTitle: "Missing payment method",
    missingPmErrorMessage: "To proceed you must select a payment method",
    pmEmptyListTitle: "No linked payment method",
    pmEmptyListDesc:
      "To proceed with the payment, you need to link a payment method to your account 😊",
    pr: {
      missingTitle: "Missing PR",
      missingDesc: "Select an option in the section: Select your PR",
      title: "Select your PR",
      noPrOption: "I don't have a PR",
    },
  },
  orderSummary: {
    title: "Order summary",
  },
  thankyou: {
    title: "Thank you for your order",
    youCanCloseThisPage: "You can close this page",
  },

  login: {
    loginTitle: "Verify your number",
    loginSubTitle: "To access the event",
    insertAValidNumber:
      "Please insert a valid phone number, including country code",
    yourNumber: "Your mobile number",
    sendCode: "Send code",
    verificationCodeTitle: "Verification code",
    invalidCode: "Invalid code",
    yourCode: "The code you received",
    confirm: "Confirm",
  },
  userMainInfoInputScreen: {
    youMustSpecifyAName: "You mus provide a name",
    yourName: "Your name",
    nameLabel: "Name",
    birthDayLabel: "Date of birth",
    selectBirthDay: "Select date of birth",
    youMustSpecifyABirthDay:
      "You must provide a date of birth and be at least 18 years old",
    confirm: "Confirm",
  },
  chooseRateScreen: {
    chooseRateTitle: "Choose rate",
    confirm: "Confirm",
    missingRateTitle: "Missing rate",
    missingRateDesc: "You must choose at least one rate",
  },
  orderQrCode: {
    title: "Your QR code",
    desc: "Present it at the entrance of the event to access.<br />Save the code by clicking on the button below",
    download: "Download QR Code",
    validFor: "This QR code is valid for:<br/>{{validForString}}",
  },
  eventMap: {
    title: "How to get there",
  },
  appStores: {
    title: "Download JustMeet and access your QR code also from the app",
  },
  joinEvent: {
    join: "Join the list",
    accessEventDesc:
      "Click the button below to view and receive the entrance QR code via email 😊",
  },
  joinedEvent: {
    showQrCode: "Show this QR code at the entrance to access the event",
    downloadQrCode: "Download QR code",
  },
  eventDate: {
    formatString: "dddd DD MMM YYYY [at] HH:mm",
  },
  myAccount: {
    deleteAccount: "Delete account",
    deleteAccountConfirmation:
      "Are you sure you want to delete your account? This action is irreversible",
  },
};
