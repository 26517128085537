import { useAuthCtx } from "../../Auth/useAuthCtx";
import { JoinedEventQrCode } from "./JoinedEventQrCode";
import { useEventIdQueryParams } from "../useEventIdQueryParams";
import { Layout } from "../../UI/Layout";
import { EventDTO } from "../EventDTO";
import { EventInfoDTO } from "../useEventInfoById";
import { UserDTO } from "../../Auth/UserDTO";
import { useEventAndEventInfoById } from "../useEventAndEventInfoById";
import { FullScreenLoading } from "../../UI/FullScreenLoading";
import { SquaredCard } from "../../UI/SquaredCard";
import { EventMap } from "../EventMap";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { useRef } from "react";
import { useDownloadQrCode } from "../../QrCode/useDownloadQrCode";
import dayjs from "dayjs";
import { Footer } from "../../UI/Footer";
import { useTranslation } from "react-i18next";
import { EventDateRenderer } from "../EventDateRenderer";
import { AppStoresDownload } from "../../Payments/AppStoresDownload";

type Props = {
  event: EventDTO;
  eventInfo: EventInfoDTO;
  authUser: UserDTO;
};

function JoinedEventInner({ event, eventInfo, authUser }: Props) {
  const { t } = useTranslation();
  const qrCodeRef = useRef<HTMLDivElement>(null);

  const { downloadQrCode } = useDownloadQrCode();

  function download() {
    if (!qrCodeRef.current) throw new Error("qrCodeRef is null");
    downloadQrCode(
      qrCodeRef.current,
      `${event.title}-${dayjs(event.date).toISOString()}`
    );
  }
  return (
    <Layout>
      <div className={"flex flex-col p-4 flex-1 space-y-4"}>
        <SquaredCard>
          <h1 className={"font-bold text-lg text-center"}>{event.title}</h1>
          <EventDateRenderer
            date={dayjs(event.date).toDate()}
            className={"text-center"}
          />
          <p className={"text-center mt-4"}>{t("joinedEvent.showQrCode")}</p>
          <div className={"flex flex-col items-center mt-4"}>
            <div ref={qrCodeRef}>
              <JoinedEventQrCode eventId={event.id} userId={authUser.id} />
            </div>
            <div className={"mt-4"}>
              <PrimarySolidButton
                onClick={download}
                label={t("joinedEvent.downloadQrCode")}
              />
            </div>
          </div>
        </SquaredCard>
        <EventMap eventInfo={eventInfo} />
        <AppStoresDownload />
      </div>
      <Footer />
    </Layout>
  );
}
export function JoinedEventScreen() {
  const { eventId } = useEventIdQueryParams();
  const { user } = useAuthCtx();
  const { event, eventInfo, loading } = useEventAndEventInfoById(
    eventId ?? undefined
  );
  if (loading) return <FullScreenLoading />;
  if (!user || !event || !eventInfo) return null; //TODO return 404

  return (
    <JoinedEventInner event={event} eventInfo={eventInfo} authUser={user} />
  );
}
