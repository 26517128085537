import { Layout } from "../UI/Layout";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { decode } from "base-64";
import { useTranslation } from "react-i18next";
import { safeGtag } from "../Analytics/safeGtag";
import { ShieldIcon } from "../UI/Icon/ShieldIcon";
import { useCreatePaymentMethod } from "./useCreatePaymentMethod";

function AddPaymentMethodInner() {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { creating, createPm } = useCreatePaymentMethod();

  function getRedirectUrl() {
    const redirect = searchParams.get("r");
    if (!redirect) return "/payment-methods";
    return decode(redirect);
  }

  async function addPm() {
    safeGtag("event", "ConfirmPaymentMethod");

    if (!stripe || !elements) throw new Error("Stripe not loaded");
    await createPm(stripe, elements);
    window.location.href = getRedirectUrl();
  }

  return (
    <>
      <Layout>
        <div className={"p-4 flex-1 flex flex-col"}>
          <h1 className={"text-center mb-4 text-2xl font-bold text-gray-900"}>
            {t("addPaymentMethod.title")}
          </h1>
          <div className={"p-4 border border-gra-200 rounded-lg bg-slate-200"}>
            <CardElement options={{}} />
          </div>
          <div className={"flex mt-4 justify-center items-center"}>
            <ShieldIcon className={"h-6 w-6 text-green-500 mr-2"} />
            <span
              dangerouslySetInnerHTML={{
                __html: t("addPaymentMethod.reassurance"),
              }}
              className={"text-gray-900"}
            />
          </div>
          <div className={"mt-auto w-full sticky bottom-4"}>
            <div className={"flex flex-col items-stretch w-full"}>
              <PrimarySolidButton
                label={t("addPaymentMethod.cta")}
                onClick={addPm}
                loading={creating}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);
export function AddPaymentMethodScreen() {
  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodInner />
    </Elements>
  );
}
