type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};
export function RadioCircle(props: Props) {
  return (
    <div
      className={[
        "rounded-full border-2 border-slate-200 p-2",
        props.checked ? "bg-primary" : "",
      ].join(" ")}
    >
      <div className={"bg-white rounded-full w-1 h-1"} />
    </div>
  );
}
