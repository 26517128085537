import { EventPRDTO } from "./EventPRDTO";
import React, { useState } from "react";
import { SquaredCard } from "../UI/SquaredCard";
import { RadioGroup } from "@headlessui/react";
import { RadioCircle } from "../UI/RadioCircle";
import { useTranslation } from "react-i18next";

function PrListItem({ pr, checked }: { pr: EventPRDTO; checked?: boolean }) {
  return (
    <div className={"flex items-center"}>
      <div className={"mr-2"}>
        <RadioCircle
          checked={checked ?? false}
          onChange={() => {
            //void
          }}
        />
      </div>

      <h2 className={"ml-2"}>{pr.userName}</h2>
      <div
        className={
          "bg-no-repeat ml-auto bg-center bg-cover w-10 h-10 rounded-full"
        }
        style={{
          backgroundImage: `url(${pr.userProfileImage.src})`,
        }}
      />
    </div>
  );
}

export const NoPrRadioValue = "no-pr";
type Props = {
  prs: EventPRDTO[];
  onSelect: (prId: string) => void;
};
export function SelectPRSection({ prs, onSelect }: Props) {
  const [selectedPr, setSelectedPr] = useState<string>();
  const { t } = useTranslation();
  if (prs.length <= 0) return null;
  return (
    <div className={""}>
      <h2 className={["mb-4 mt-6 font-bold text-gray-600"].join(" ")}>
        {t("checkout.pr.title")}
      </h2>
      <SquaredCard>
        <RadioGroup
          className={"space-y-4 flex flex-col relative"}
          value={selectedPr}
          onChange={(prId: string) => {
            setSelectedPr(prId);
            onSelect(prId);
          }}
        >
          <div className={"flex flex-col space-y-4"}>
            {prs.map((pr) => (
              <RadioGroup.Option key={pr.userId} value={pr.userId}>
                <PrListItem checked={selectedPr === pr.userId} pr={pr} />
              </RadioGroup.Option>
            ))}
            <RadioGroup.Option value={NoPrRadioValue}>
              <div className={"flex items-center"}>
                <div className={"mr-2"}>
                  <RadioCircle
                    checked={selectedPr === NoPrRadioValue}
                    onChange={() => {
                      //void
                    }}
                  />
                </div>
                <div className={"ml-2"}>
                  <h2>{t("checkout.pr.noPrOption")}</h2>
                </div>
              </div>
            </RadioGroup.Option>
          </div>
        </RadioGroup>
      </SquaredCard>
    </div>
  );
}
