import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeFirebase } from "./Firebase/initializeFirebase";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { setupI18n } from "./I18n/setupI18n";
import { configureDayJs } from "./Date/configureDayJs";
configureDayJs();
setupI18n().then(() => console.log("i18n loaded"));
if (process.env.REACT_APP_SENTRY_DSN) {
  console.log("Sentry release is", process.env.REACT_APP_SENTRY_RELEASE);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV ?? "stage",
    release: process.env.REACT_APP_SENTRY_RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

initializeFirebase();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
