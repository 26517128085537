import { useCreatePaymentMethod } from "./useCreatePaymentMethod";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCallback, useEffect } from "react";
import { SquaredCard } from "../UI/SquaredCard";
import { RadioCircle } from "../UI/RadioCircle";
import { NewPmCallback } from "./NewPmCallback";
import { useTranslation } from "react-i18next";

export type PmIdBagResponse = {
  ok: boolean;
  pmId?: string;
  error?: string;
};

export function AddPmForm({
  checked,
  setNewPmCallback,
}: {
  checked: boolean;
  setNewPmCallback: (cb: NewPmCallback) => void;
}) {
  const { t } = useTranslation();
  const { createPm } = useCreatePaymentMethod();
  const stripe = useStripe();
  const elements = useElements();
  const newPm = useCallback(async (): Promise<PmIdBagResponse> => {
    if (!stripe || !elements)
      return {
        ok: false,
        error: `Missing elements`,
      };
    try {
      const pmId = await createPm(stripe, elements);
      return {
        ok: true,
        pmId,
      };
    } catch (e) {
      return {
        ok: false,
        error: `Ops something went wrong`,
      };
    }
  }, [stripe, elements, createPm]);
  useEffect(() => {
    setNewPmCallback(newPm);
  }, [newPm, setNewPmCallback]);
  return (
    <div>
      <SquaredCard>
        <div className={"flex items-center"}>
          <div className={"mr-2"}>
            <RadioCircle
              checked={checked ?? false}
              onChange={() => {
                //void
              }}
            />
          </div>
          <h3 className={"text-gray-800"}>{t("checkout.newPm")}</h3>
        </div>
        <div className={"mt-4"}>
          <CardElement
            options={{
              disabled: !checked,
              classes: {
                base: "border border-gray-200 rounded-md p-2 bg-slate-200",
              },
            }}
          />
        </div>
      </SquaredCard>
    </div>
  );
}
