import { PropsWithChildren } from "react";
import { AuthCtx } from "./AuthCtx";
import { useMe } from "./useMe";
import { FullScreenLoading } from "../UI/FullScreenLoading";

type Props = PropsWithChildren<unknown>;
export function AuthCtxProvider(props: Props) {
  const { loading, user } = useMe();
  if (!user && loading) return <FullScreenLoading />;
  return (
    <AuthCtx.Provider
      value={{
        user,
      }}
    >
      {props.children ?? null}
    </AuthCtx.Provider>
  );
}
