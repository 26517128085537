export const it = {
  paymentMethods: {
    title: "Metodi di pagamento",
    newPaymentMethodCta: "Nuovo metodo di pagamento",
  },
  addPaymentMethod: {
    title: "Aggiungi un metodo di pagamento",
    cta: "Aggiungi metodo di pagamento",
    reassurance:
      'Pagamenti sicuri con <a class="text-blue-600 underline" href="https://stripe.com">Stripe</a>',
  },
  checkout: {
    newPm: "Nuovo metodo di pagamento",
    sessionExpired:
      "La tua sessione è scaduta, ricarica la pagina per riprovare",
    soldOutTitle: "Tutto esaurito",
    soldOutExpl: "Sembra che quello che stai cercando sia esaurito 😔",
    completeBefore:
      "Completa l'ordine entro {{minutes}} minuti e {{seconds}} secondi",
    somethingWentWrongTitle: "Qualcosa è andato storto",
    somethingWentWrongDescription:
      "Si è verificato un errore durante il pagamento, ti preghiamo di riprovare più tardi",
    paymentMethodTitle: "Metodo di pagamento",
    addPm: "Aggiungi un metodo di pagamento",
    pay: "Paga",
    addPaymentMethodErrorTitle: "Aggiungi un metodo di pagamento",
    addPaymentMethodErrorMessage:
      'Per procedere all\'acquisto devi associare un metodo di pagamento al tuo account. Puoi farlo cliccando su "Aggiungi un metodo di pagamento"',
    missingPmErrorTitle: "Metodo di pagamento mancante",
    missingPmErrorMessage:
      "Per procedere devi selezionare un metodo di pagamento",
    pmEmptyListTitle: "Nessun metodo di pagamento associato",
    pmEmptyListDesc:
      "Per procedere al pagamento, devi associare un metodo di pagamento al tuo account 😊",
    pr: {
      missingTitle: "PR mancante",
      missingDesc: "Seleziona un'opzione nella sezione: Seleziona il tuo PR",
      title: "Seleziona il tuo PR",
      noPrOption: "Non ho nessun PR",
    },
  },
  orderSummary: {
    title: "Riepilogo ordine",
  },
  thankyou: {
    title: "Grazie per il tuo ordine",
    youCanCloseThisPage: "Puoi chiudere questa pagina",
  },
  login: {
    loginTitle: "Verifica il tuo numero",
    loginSubTitle: "Per accedere all'evento",
    insertAValidNumber:
      "Inserisci un numero di telefono valido, compreso di prefisso",
    yourNumber: "Il tuo numero di telefono",
    sendCode: "Invia codice",
    verificationCodeTitle: "Codice di verifica",
    invalidCode: "Il codice inserito non è valido",
    yourCode: "Il codice che hai ricevuto",
    confirm: "Conferma",
  },
  userMainInfoInputScreen: {
    youMustSpecifyAName: "Devi specificare un nome",
    yourName: "Il tuo nome",
    nameLabel: "Nome",
    birthDayLabel: "Data di nascita",
    selectBirthDay: "Seleziona la data di nascita",
    youMustSpecifyABirthDay:
      "Devi specificare una data di nascita e devi avere almeno 18 anni",
    confirm: "Conferma",
  },
  chooseRateScreen: {
    chooseRateTitle: "Scegli tariffa",
    confirm: "Conferma",
    missingRateTitle: "Tariffa mancante",
    missingRateDesc: "Devi scegliere almeno una tariffa",
  },
  orderQrCode: {
    title: "Il tuo qr per accedere all'evento",
    desc: "Presentalo all'ingresso dell'evento per accedere.<br />Salva il codice cliccando sul bottone qui sotto",
    download: "Scarica QR Code",
    validFor: "Questo codice QR è valido per:<br/>{{validForString}}",
  },
  eventMap: {
    title: "Come arrivare",
  },
  appStores: {
    title: "Scarica JustMeet e accedi al tuo codice QR anche dall'app",
  },
  joinEvent: {
    join: "Iscriviti in lista",
    accessEventDesc:
      "Clicca il pulsante qui sotto per visualizzare e ricevere via email il QR code d'ingresso 😊",
  },
  joinedEvent: {
    showQrCode: "Mostra questo QR code all'ingresso per accedere all'evento",
    downloadQrCode: "Scarica QR code",
  },
  eventDate: {
    formatString: "dddd DD MMM YYYY [alle] HH:mm",
  },
  myAccount: {
    deleteAccount: "Cancella account",
    deleteAccountConfirmation:
      "Sei sicuro di voler cancellare il tuo account? Questa operazione è irreversibile",
  },
};
