import { useParams } from "react-router-dom";

export function useEventIdOrThrow() {
  const { eventId } = useParams<{
    eventId: string;
  }>();
  if (!eventId) {
    throw new Error("eventId is required");
  }

  return {
    eventId,
  };
}
