import { gql, useMutation } from "@apollo/client";

const UPDATE_ME = gql`
  mutation updateMe($name: String, $birthDate: DateTime) {
    updateMe(name: $name, birthDate: $birthDate) {
      id
      birthDate
      name
    }
  }
`;

type Response = {
  updateMe: {
    id: string;
  };
};

type Input = {
  name: string;
  birthDate: string;
};
export function useUpdateMe() {
  const [updateMeMutation, { loading }] = useMutation<Response, Input>(
    UPDATE_ME
  );

  async function updateMe({ name, birthDate }: Input) {
    await updateMeMutation({
      variables: {
        name,
        birthDate,
      },
    });
  }

  return {
    updateMe,
    loading,
  };
}
