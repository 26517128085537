import React, { useMemo } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ApolloClientFactory } from "./ApolloClient/ApolloClientFactory";
import { AuthByTokenScreen } from "./Auth/AuthByTokenScreen";
import { AuthCtxProvider } from "./Auth/AuthCtxProvider";
import { useAuthCtx } from "./Auth/useAuthCtx";
import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { MainScreen } from "./MainScreen";
import { PaymentMethodScreen } from "./Payments/PaymentMethodScreen";
import { AddPaymentMethodScreen } from "./Payments/AddPaymentMethodScreen";
import { CheckoutScreen } from "./Payments/CheckoutScreen";
import { ThankyouScreen } from "./Payments/ThankyouScreen";
import { TestScreen } from "./Test/TestScreen";
import { RouteWithoutI18n } from "./I18n/RouteWithoutI18n";
import { LocalizeRoutes } from "./I18n/LocalizeRoutes";
import { LoginScreen } from "./Auth/LoginWithPhone";
import { UserMainInfoInputScreen } from "./User/UserMainInfoInputScreen";
import { WithUserInfoProtectedRouter } from "./User/WithUserInfoProtectedRouter";
import { ChooseRateScreen } from "./Payments/ChooseRateScreen";
import { EventOrderScreen } from "./Payments/EventOrderScreen";
import { JoinEventScreen } from "./Event/JoinEvent/JoinEventScreen";
import { JoinedEventScreen } from "./Event/JoinEvent/JoinedEventScreen";
import { MyAccountScreen } from "./User/MyAccountScreen";
import { TokenScreen } from "./Auth/TokenScreen";
import { QrCodeV2Screen, QrCodeV2ScreenPath } from "./QrCode/V2/QrCodeV2Screen";
import {
  ScanQrCodeV2Screen,
  ScanQrCodeV2ScreenPath,
} from "./QrCode/V2/Scan/ScanQrCodeV2Screen";
import {
  ScanReportScreen,
  ScanReportScreenPath,
} from "./QrCode/V2/Scan/ScanReportScreen";
//
function InnerRoutesComponent() {
  const { user } = useAuthCtx();
  return (
    <LocalizeRoutes>
      <Route path="/lwt" element={<AuthByTokenScreen />} />
      <Route
        path="/"
        element={
          <ProtectedRoute user={user}>
            <MainScreen />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/payment-methods"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <PaymentMethodScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path="/user-info-onboarding"
        element={
          <ProtectedRoute redirectTo={"/login"} user={user}>
            <UserMainInfoInputScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/join"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <JoinEventScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path="/joined-event"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <JoinedEventScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path="/my-account"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <MyAccountScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path="/add-pm"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <AddPaymentMethodScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path="/thankyou"
        element={
          <ProtectedRoute user={user}>
            <ThankyouScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path="/checkout"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <CheckoutScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route path="/order" element={<EventOrderScreen />} />
      <Route
        path="/rates"
        element={
          <WithUserInfoProtectedRouter user={user}>
            <ChooseRateScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route path="/token" element={<TokenScreen />} />
      <Route path={QrCodeV2ScreenPath} element={<QrCodeV2Screen />} />
      <Route
        path={ScanQrCodeV2ScreenPath}
        element={
          <WithUserInfoProtectedRouter user={user}>
            <ScanQrCodeV2Screen />
          </WithUserInfoProtectedRouter>
        }
      />
      <Route
        path={ScanReportScreenPath}
        element={
          <WithUserInfoProtectedRouter user={user}>
            <ScanReportScreen />
          </WithUserInfoProtectedRouter>
        }
      />
      <RouteWithoutI18n>
        <Route path="/test" element={<TestScreen />} />
      </RouteWithoutI18n>
    </LocalizeRoutes>
  );
}

function RouteConfigComponent() {
  return (
    <BrowserRouter>
      <InnerRoutesComponent />
    </BrowserRouter>
  );
}

function App() {
  const client = useMemo(() => new ApolloClientFactory().create(), []);

  return (
    <ApolloProvider client={client}>
      <AuthCtxProvider>
        <RouteConfigComponent />
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;
