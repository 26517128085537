import { gql, useQuery } from "@apollo/client";
import { EventOrderDTO } from "./EventOrderDTO";

const GET_ORDER_BY_ID = gql`
  query getEventOrderById($orderId: String!) {
    getEventOrderById(orderId: $orderId) {
      id
      addressString
      eventDate
      eventId
      eventDescription
      eventTitle
      rates {
        rate {
          price
          name
          description
          id
        }
        quantity
      }
      orderTotal
      eventGallery {
        src
      }
      justMeetFees
      qrCodeData
    }
  }
`;

type Response = {
  getEventOrderById: EventOrderDTO;
};

type Input = {
  orderId: string;
};

export function useEventOrderById(orderId: string) {
  const { data, loading } = useQuery<Response, Input>(GET_ORDER_BY_ID, {
    variables: { orderId },
  });

  return {
    order: data?.getEventOrderById,
    loading,
  };
}
