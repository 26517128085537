import { useAuthCtx } from "../Auth/useAuthCtx";
import React, { useState } from "react";
import { Layout } from "../UI/Layout";
import { SquaredCard } from "../UI/SquaredCard";
import { TextButton } from "../UI/TextButton";
import { useDeleteAccount } from "./useDeleteAccount";
import { useTranslation } from "react-i18next";
import { signOut } from "firebase/auth";
import { getFirebaseAuth } from "../Firebase/getFirebaseAuth";

export function MyAccountScreen() {
  const { user } = useAuthCtx();
  const { deleteAccount } = useDeleteAccount();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  async function deleteMyAccount() {
    if (window.confirm(t("myAccount.deleteAccountConfirmation"))) {
      try {
        setLoading(true);
        await deleteAccount();
        await signOut(getFirebaseAuth());
        window.location.href = "https://justmeetapp.com";
      } finally {
        setLoading(false);
      }
    }
  }
  if (!user) return null; //TODO return 404
  return (
    <Layout>
      <div className={"flex-1 p-4 flex-col space-y-4"}>
        <SquaredCard className={"flex flex-col space-y-2"}>
          <h1 className={"font-bold"}>{user.name}</h1>
          {user.email ? <p>{user.email}</p> : null}
        </SquaredCard>
        <SquaredCard>
          <TextButton
            loading={loading}
            label={t("myAccount.deleteAccount")}
            onClick={deleteMyAccount}
          />
        </SquaredCard>
      </div>
    </Layout>
  );
}
