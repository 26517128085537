import { Layout } from "../../../UI/Layout";
import { useEventIdOrThrow } from "./useEventIdOrThrow";
import { useScanReport } from "./useScanReport";
import { FullScreenLoading } from "../../../UI/FullScreenLoading";
import { ErrorFeedback } from "../../../UI/Feedback/ErrorFeedback";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import dayjs from "dayjs";

export const ScanReportScreenPath = "/qr-code/v2/:eventId/scan-report";
export function ScanReportScreen() {
  const { eventId } = useEventIdOrThrow();
  const { isLoading, report } = useScanReport(eventId);

  if (isLoading) return <FullScreenLoading />;
  if (!report)
    return (
      <Layout>
        <div className={"p-4"}>
          <ErrorFeedback description={"Ops, evento non trovato"} />;
        </div>
      </Layout>
    );
  return (
    <Layout fullWidth>
      <div className={"p-4 flex-1 flex flex-col space-y-4"}>
        <h1 className={"font-bold"}>
          Report per l'evento "{report.event.title}" di{" "}
          {dayjs(report.event.date).locale("it").format("dddd DD MMM YYYY")}
        </h1>
        <div>
          <ResponsiveContainer width={"100%"} height={500}>
            <LineChart
              data={report.reportEntries.map((e) => ({
                ...e,
                date: dayjs(e.date).format("DD/MM/YYYY"),
              }))}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip
                labelFormatter={(v) => {
                  const date = dayjs(v, "DD/MM/YYYY");
                  return date.locale("it").format("dddd DD MMM YYYY");
                }}
                formatter={(v) => [v, "N° Scan"]}
              />
              <Line type="monotone" dataKey="accessCount" stroke="#8884d8">
                <LabelList />
              </Line>
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Layout>
  );
}
