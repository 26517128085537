import { ProtectedRoute } from "../Auth/ProtectedRoute";
import { PropsWithChildren } from "react";
import { UserDTO } from "../Auth/UserDTO";
type Props = PropsWithChildren<{ user?: UserDTO }>;
export function WithUserInfoProtectedRouter({ user, children }: Props) {
  return (
    <ProtectedRoute
      user={user}
      needToHaveUserInfo
      redirectTo={"/user-info-onboarding"}
    >
      {children ?? null}
    </ProtectedRoute>
  );
}
