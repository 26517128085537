import { HTMLInputTypeAttribute } from "react";

type Props = {
  placeholder?: string;
  center?: boolean;
  value: string;
  onChange: (value: string) => void;
  onEnterPressed?: () => void;
  errorString?: string;
  autoFocus?: boolean;
  type?: HTMLInputTypeAttribute;
};
export function TextInput(props: Props) {
  return (
    <div className={"flex flex-col items-center w-full"}>
      <input
        type={props.type}
        autoFocus={props.autoFocus}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnterPressed) {
            props.onEnterPressed();
          }
        }}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.value}
        placeholder={props.placeholder}
        className={[
          "border placeholder-gray-400 border-gray-200 rounded-md h-10 min-w-[200px] p-2 w-full",
          props.center ? "text-center" : "",
          props.errorString ? "border-red-600" : "border-gray-200",
        ].join(" ")}
      />
      {props.errorString ? (
        <span
          className={
            "text-red-600 text-sm text-center mt-2 flex-shrink flex-grow-0"
          }
        >
          {props.errorString}
        </span>
      ) : null}
    </div>
  );
}
