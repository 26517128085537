import { gql, useQuery } from "@apollo/client";
import { EventRateDTO } from "./EventRateDTO";

const GET_RATES = gql`
  query getEventRatesByEventId($eventId: String!) {
    getEventRatesByEventId(eventId: $eventId) {
      id
      price
      name
      description
      eventId
    }
  }
`;

type Response = {
  getEventRatesByEventId: EventRateDTO[];
};

type Input = {
  eventId: string;
};

export function useEventRates(eventId: string) {
  const { data, loading } = useQuery<Response, Input>(GET_RATES, {
    variables: {
      eventId,
    },
  });

  return {
    rates: data?.getEventRatesByEventId ?? [],
    loading,
  };
}
