import { CheckoutSessionCtx } from "./CheckoutSessionCtx";
import { PropsWithChildren, useCallback, useState } from "react";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

const SESSION_ID_KEY = "checkoutSessionId";
const SESSION_ID_CREATED_AT = "checkoutSessionIdCreatedAt";
type Props = PropsWithChildren<unknown>;
export function CheckoutSessionCtxProvider({ children }: Props) {
  const createNewSessionId = useCallback((newSessionId = nanoid()) => {
    localStorage.setItem(SESSION_ID_KEY, newSessionId);
    localStorage.setItem(SESSION_ID_CREATED_AT, dayjs().unix().toString());
    return newSessionId;
  }, []);
  const [sessionId, setSessionId] = useState(getSessionId());

  function sessionIsExpired() {
    try {
      const createdAt = localStorage.getItem(SESSION_ID_CREATED_AT);
      if (!createdAt) return true;
      const createdAtDate = dayjs.unix(parseInt(createdAt));
      return dayjs().diff(createdAtDate, "minute") > 14;
    } catch (e) {
      return true;
    }
  }

  function getSessionId(): string {
    if (sessionIsExpired()) return createNewSessionId();
    const foundSessionId = localStorage.getItem(SESSION_ID_KEY);
    if (!foundSessionId) return createNewSessionId();
    return foundSessionId;
  }

  const setNewSessionId = useCallback(
    (sessionId: string): void => {
      setSessionId(createNewSessionId(sessionId));
    },
    [createNewSessionId]
  );

  return (
    <CheckoutSessionCtx.Provider
      value={{
        sessionId: sessionId,
        deleteSession: () => {
          localStorage.removeItem(SESSION_ID_KEY);
        },
        setSessionId: setNewSessionId,
      }}
    >
      {children ?? null}
    </CheckoutSessionCtx.Provider>
  );
}
