import { useEventIdQueryParams } from "../useEventIdQueryParams";
import { Layout } from "../../UI/Layout";
import { useEventById } from "../useEventById";
import { EventDTO } from "../EventDTO";
import { FullScreenLoading } from "../../UI/FullScreenLoading";
import { useEventFeaturedImage } from "../useEventFeaturedImage";
import { PrimarySolidButton } from "../../UI/PrimarySolidButton";
import { FormattedText } from "../FormattedText";
import { EventMap } from "../EventMap";
import { EventInfoDTO, useEventInfoById } from "../useEventInfoById";
import { SquaredCard } from "../../UI/SquaredCard";
import { useJoinEvent } from "../useJoinEvent";
import { useToJoinedEventScreen } from "./useToJoinedEventScreen";
import { Navigate } from "react-router-dom";
import { Footer } from "../../UI/Footer";
import { useTranslation } from "react-i18next";
import { EventDateRenderer } from "../EventDateRenderer";
import dayjs from "dayjs";

type Props = {
  event: EventDTO;
  eventInfo: EventInfoDTO;
};
function JoinEventScreenInner({ event, eventInfo }: Props) {
  const { featuredImg } = useEventFeaturedImage(event);
  const { joinEvent, loading: joining } = useJoinEvent();

  const { toJoinedEventScreen, getJoinedEventUrl } = useToJoinedEventScreen();

  const { t } = useTranslation();

  if (event.participationConfirmationNeeded) return null; //TODO return 404

  if (event.loggedUserIsParticipating)
    return <Navigate to={getJoinedEventUrl(event.id)} />;

  async function join() {
    await joinEvent(event.id);
    toJoinedEventScreen(event.id);
  }
  return (
    <Layout>
      <div
        style={{
          backgroundImage: `url(${featuredImg.src})`,
        }}
        className={"bg-no-repeat bg-center min-h-[200px] w-full"}
      />
      <div className={"p-4 flex-1 flex flex-col"}>
        <div className={"flex flex-col space-y-4"}>
          <SquaredCard>
            <div className={"flex flex-col space-y-2"}>
              <EventDateRenderer date={dayjs(event.date).toDate()} />
              <h1 className={"font-bold text-lg"}>{event.title}</h1>
              {event.description ? (
                <FormattedText text={event.description ?? ""} />
              ) : null}
            </div>
          </SquaredCard>
          <SquaredCard>
            <p>{t("joinEvent.accessEventDesc")}</p>
          </SquaredCard>
          <EventMap eventInfo={eventInfo} />
        </div>
        <div className={"mt-auto sticky bottom-0 py-4 w-full flex flex-col"}>
          <PrimarySolidButton
            onClick={join}
            loading={joining}
            label={t("joinEvent.join")}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export function JoinEventScreen() {
  const { eventId } = useEventIdQueryParams();
  if (!eventId) throw new Error("eventId is required");
  const { event, loading } = useEventById(eventId);
  const { event: eventInfo, loading: loadingEventInfo } =
    useEventInfoById(eventId);
  if (loading || loadingEventInfo) return <FullScreenLoading />;
  if (!event || !eventInfo) return null; //TODO show 404
  return <JoinEventScreenInner eventInfo={eventInfo} event={event} />;
}
