import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  fullWidth?: boolean;
}>;
export function Layout(props: Props) {
  return (
    <div
      className={[
        "min-h-screen flex flex-col mx-auto bg-slate-100 relative",
        props.fullWidth ? "" : "max-w-[400px]",
      ].join(" ")}
    >
      {props.children ?? null}
    </div>
  );
}
