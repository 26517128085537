import { SquaredCard } from "../UI/SquaredCard";
import { useTranslation } from "react-i18next";

export function AppStoresDownload() {
  const { t } = useTranslation();
  return (
    <SquaredCard>
      <p className={"mb-2 text-sm text-gray-600"}>{t("appStores.title")}</p>
      <div
        className={
          "flex flex-col xs:flex-row items-center justify-center xs:space-x-2 space-y-2 xs:space-y-0"
        }
      >
        <a href={"https://apps.apple.com/app/just-meet-app/id1602493982"}>
          <img src="/app-store-2.png" alt="App Store" className="h-9" />
        </a>
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.justmeet.app"
          }
        >
          <img src="/google-play-2.png" className={"h-9"} alt="Google Play" />
        </a>
      </div>
    </SquaredCard>
  );
}
