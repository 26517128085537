import { PrimaryLoadingIndicator } from "./PrimaryLoadingIndicator";

type Props = {
  label: string;
  onClick: () => void;
  loading?: boolean;
};
export function TextButton(props: Props) {
  return (
    <a
      className={"underline text-primary flex items-center space-x-2"}
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
      href={""}
    >
      {props.loading ? <PrimaryLoadingIndicator size={"small"} /> : null}
      <span>{props.label}</span>
    </a>
  );
}
