import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export function useQrCodeById(qrCodeId: string) {
  const [loading, setLoading] = useState(true);
  const [qrCodeData, setQrCodeData] = useState<{
    data: string;
    eventTitle: string;
    eventDate: string;
    userFullName: string;
    eventEndDate: string;
  }>();
  const getQrCodeById = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axios.get<{
        data: string;
        eventTitle: string;
        eventDate: string;
        userFullName: string;
        eventEndDate: string;
      }>(
        `${process.env.REACT_APP_JUSTMEET_SERVER_BASE_URL}/event-access/qr-code/v2/${qrCodeId}`
      );
      setQrCodeData(data);
    } finally {
      setLoading(false);
    }
  }, [qrCodeId]);

  useEffect(() => {
    (async function start() {
      await getQrCodeById();
    })();
  }, [getQrCodeById]);

  return {
    loading,
    data: qrCodeData?.data ?? "",
    eventTitle: qrCodeData?.eventTitle ?? "",
    eventDate: qrCodeData?.eventDate ?? "",
    userFullName: qrCodeData?.userFullName ?? "",
    eventEndDate: qrCodeData?.eventEndDate ?? "",
  };
}
