import xss from "xss";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

type Props = {
  description: string;
};
export function ErrorFeedback({ description }: Props) {
  return (
    <div
      className={
        "bg-red-200 text-red-800 rounded-md w-full p-3 flex items-center"
      }
    >
      <ExclamationCircleIcon className={"w-6 h-6  mr-3 flex-shrink-0"} />
      <p
        className={""}
        dangerouslySetInnerHTML={{
          __html: xss(description),
        }}
      />
    </div>
  );
}
