import { SquaredCard } from "../UI/SquaredCard";
import dayjs from "dayjs";
import { LineDivider } from "../UI/LineDivider";
import { EventOrderDTO } from "./EventOrderDTO";
import React from "react";
type Props = {
  order: EventOrderDTO;
};
export function OrderSummary({ order }: Props) {
  return (
    <>
      <SquaredCard>
        <div className={"flex flex-col space-y-4"}>
          {order.rates.map((r, index) => {
            return (
              <React.Fragment key={r.rate.id}>
                <div className={"flex flex-col"}>
                  <div className={"flex items-center justify-between"}>
                    <h3 className={"font-bold"}>{order.eventTitle}</h3>
                    <span className={"ml-2 text-gray-600"}>x{r.quantity}</span>
                  </div>
                  <div className={"flex flex-col"}>
                    <span className={"text-gray-600 text-sm"}>
                      {dayjs(order.eventDate).format(
                        "ddd DD MMM YYYY [alle] HH:mm"
                      )}
                    </span>
                    {order.addressString ? (
                      <span className={"text-gray-600 text-sm"}>
                        {order.addressString}
                      </span>
                    ) : null}
                  </div>
                  <span className={"text-gray-600 mt-2 ml-auto"}>
                    {r.rate.price} €
                  </span>
                </div>
                {index < order.rates.length - 1 ? <LineDivider /> : null}
              </React.Fragment>
            );
          })}
        </div>
        <LineDivider />
        <div className={"flex items-center justify-between"}>
          <span className={"text-gray-600 mt-2 font-bold"}>Commissioni</span>
          <span className={"text-gray-600 ml-2 mt-2"}>
            {order.justMeetFees} €
          </span>
        </div>
        <div className={"flex items-center justify-between"}>
          <span className={"text-gray-600 mt-2 font-bold"}>Totale</span>
          <span className={"text-gray-600 ml-2 mt-2"}>
            {order.orderTotal} €
          </span>
        </div>
      </SquaredCard>
    </>
  );
}
