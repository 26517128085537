import { MouseEvent } from "react";
import { WhiteLoadingIndicator } from "./WhiteLoadingIndicator";

type Props = {
  label: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
};
export function PrimarySolidButton(props: Props) {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={
        "bg-primary rounded-full px-8 py-2 text-white flex items-center justify-center disabled:bg-gray-300"
      }
    >
      <span>{props.label}</span>
      {props.loading ? (
        <span className={"ml-2"}>
          <WhiteLoadingIndicator size={"small"} />
        </span>
      ) : null}
    </button>
  );
}
