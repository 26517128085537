import { useEventById } from "./useEventById";
import { useEventInfoById } from "./useEventInfoById";

export function useEventAndEventInfoById(eventId?: string) {
  const { event, loading: loadingEvent } = useEventById(eventId);
  const { event: eventInfo, loading: loadingInfo } = useEventInfoById(eventId);
  if (loadingEvent || loadingInfo)
    return {
      loading: true,
    };

  return {
    loading: false,
    event,
    eventInfo,
  };
}
