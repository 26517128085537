import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const UPDATE_EMAIL_MUTATION = gql`
  mutation updateEmail($email: String!) {
    updateEmail(email: $email) {
      id
      email
    }
  }
`;

type Response = {
  updateEmail: {
    id: string;
    email: string;
  };
};
type Input = {
  email: string;
};
export function useUpdateEmail() {
  const [updateEmailMutation, { loading }] = useMutation<Response, Input>(
    UPDATE_EMAIL_MUTATION
  );

  const updateEmail = useCallback(
    async (email: string) => {
      await updateEmailMutation({
        variables: {
          email,
        },
      });
    },
    [updateEmailMutation]
  );

  return {
    loading,
    updateEmail,
  };
}
