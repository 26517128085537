import { useNavigate, useSearchParams } from "react-router-dom";
import { useEventRates } from "./useEventRates";
import { FullScreenLoading } from "../UI/FullScreenLoading";
import { Layout } from "../UI/Layout";
import { Card } from "../UI/Card";
import { EventRateDTO } from "./EventRateDTO";
import { encode } from "base-64";
import { useTranslation } from "react-i18next";
import { useLang } from "../I18n/useLang";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import _ from "lodash";
import { RateWithQty } from "./RateWithQty";
import { ErrorNotificationToast } from "../UI/ErrorNotificationToast";
import { useOrderChannel } from "./useOrderChannel";
import { Header } from "../UI/Header";
import { Footer } from "../UI/Footer";

function RateCard({
  rate,
  onIncrease,
  onDecrease,
  qty = 0,
}: {
  rate: EventRateDTO;
  onIncrease: () => void;
  onDecrease: () => void;
  qty?: number;
}) {
  return (
    <Card>
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-center justify-between flex-1"}>
          <div className={"mr-2"}>
            <h3 className={"font-bold"}>{rate.name}</h3>
            {rate.description ? (
              <p className={"text-gray-600"}>{rate.description}</p>
            ) : null}
          </div>
          <p className={"shrink-0"}>{rate.price} €</p>
        </div>

        <div className={"flex-col flex items-center space-y-2 ml-4"}>
          <div
            onClick={() => onIncrease()}
            className={"p-2 bg-gray-200 rounded"}
          >
            <PlusIcon className={"w-4 h-4"} />
          </div>
          <div className={"text-gray-500"}>{qty}</div>
          <div
            onClick={() => onDecrease()}
            className={"p-2 bg-gray-200 rounded"}
          >
            <MinusIcon className={"w-4 h-4"} />
          </div>
        </div>
      </div>
    </Card>
  );
}

function ChooseRateScreenInner({ eventId }: { eventId: string }) {
  const { rates, loading } = useEventRates(eventId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useLang();
  const [rateQty, setRateQty] = useState<Record<string, number>>({});
  const [showSelectRateToast, setShowSelectRateToast] = useState(false);
  const channel = useOrderChannel();
  useEffect(() => {
    const newQty = rates.reduce((res, curr) => {
      res[curr.id] = 1;
      return res;
    }, {} as Record<string, number>);
    setRateQty(newQty);
  }, [rates]);
  if (loading) return <FullScreenLoading />;

  function buildCheckoutUrl(ratesWithQty: RateWithQty[]) {
    const data = {
      eventId: eventId,
      rates: ratesWithQty.map((r) => ({
        rateId: r.rate.id,
        qty: r.qty,
      })),
    };
    return `/${lang}/checkout?d=${encode(JSON.stringify(data))}&c=${channel}`;
  }

  function increaseRate(rate: EventRateDTO) {
    setRateQty({
      ...rateQty,
      [rate.id]: (rateQty[rate.id] ?? 0) + 1,
    });
  }

  function decreaseRate(rate: EventRateDTO) {
    if (rateQty[rate.id] <= 0) return;
    setRateQty({
      ...rateQty,
      [rate.id]: (rateQty[rate.id] ?? 0) - 1,
    });
  }

  function getRateWithQty(): RateWithQty[] {
    const rateMap = _.keyBy(rates, (r) => r.id);
    return _.reduce(
      rateQty,
      (acc, qty, rateId) => {
        if (qty <= 0) return acc;
        const rate = rateMap[rateId];
        if (!rate) throw new Error(`Rate ${rateId} not found`); //Should never happen
        acc.push({
          rate,
          qty,
        });
        return acc;
      },
      [] as RateWithQty[]
    );
  }

  function confirm() {
    const ratesWithQty = getRateWithQty();
    if (ratesWithQty.length <= 0) return setShowSelectRateToast(true);
    navigate(buildCheckoutUrl(ratesWithQty));
  }

  return (
    <Layout>
      <Header />
      <ErrorNotificationToast
        show={showSelectRateToast}
        durationInMs={4000}
        close={() => setShowSelectRateToast(false)}
        description={t("chooseRateScreen.missingRateDesc")}
        title={t("chooseRateScreen.missingRateTitle")}
      />
      <div className={"p-4 flex flex-col min-h-screen"}>
        <h1 className={"font-bold text-lg"}>
          {t("chooseRateScreen.chooseRateTitle")}
        </h1>
        <div className={"mt-4 flex flex-col space-y-4"}>
          {rates.map((rate) => (
            <RateCard
              qty={rateQty[rate.id] ?? 0}
              onDecrease={() => decreaseRate(rate)}
              onIncrease={() => increaseRate(rate)}
              key={rate.id}
              rate={rate}
            />
          ))}
        </div>
        <div
          className={"mt-auto flex sticky bottom-0 py-3 flex-col items-stretch"}
        >
          <PrimarySolidButton
            onClick={confirm}
            label={t("chooseRateScreen.confirm")}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export function ChooseRateScreen() {
  const [params] = useSearchParams();
  const eventId = params.get("eid");
  if (!eventId) return null; //TODO return 404
  return <ChooseRateScreenInner eventId={eventId} />;
}
