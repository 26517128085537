import { gql, useQuery } from "@apollo/client";
import { EventDTO } from "./EventDTO";

const GET_EVENT_BY_ID = gql`
  query getEventById($eventId: ID!) {
    getEventById(id: $eventId) {
      ... on VirtualEvent {
        id
        title
        description
        virtualLocation
        date
        type
        creatorStripeAccountId
        gallery {
          src
          thumbnail
        }
        loggedUserIsParticipating
        participationConfirmationNeeded
      }
      ... on Event {
        id
        title
        description
        addressString
        date
        type
        creatorStripeAccountId
        gallery {
          src
          thumbnail
        }
        loggedUserIsParticipating
        participationConfirmationNeeded
      }
    }
  }
`;

type GetEventResponse = {
  getEventById: EventDTO;
};

type GetEventInput = {
  eventId: string;
};

export function useEventById(eventId?: string | null) {
  const { data, loading } = useQuery<GetEventResponse, GetEventInput>(
    GET_EVENT_BY_ID,
    {
      variables: {
        eventId: eventId as string,
      },
      skip: !eventId,
    }
  );

  return {
    event: data?.getEventById,
    loading,
  };
}
