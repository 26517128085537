import { Layout } from "../UI/Layout";
import { Card } from "../UI/Card";
import { TextInput } from "../UI/TextInput";
import { useState } from "react";
import { UiErrorState } from "../UI/UiErrorState";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { useUpdateMe } from "./useUpdateMe";
import { useParams, useSearchParams } from "react-router-dom";
import { decode } from "base-64";
import EmailValidator from "email-validator";
import DatePicker, {
  Day,
  DayValue,
} from "@amir04lm26/react-modern-calendar-date-picker";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import dayjs, { Dayjs } from "dayjs";
import { UserDTO } from "../Auth/UserDTO";
import { useAuthCtx } from "../Auth/useAuthCtx";
import { useTranslation } from "react-i18next";
import { Header } from "../UI/Header";
import { Footer } from "../UI/Footer";
import { useUpdateEmail } from "./useUpdateEmail";
function Label({ label }: { label: string }) {
  return <label className={"font-bold"}>{label}</label>;
}

type Props = {
  user: UserDTO;
};
function UserMainInfoInputScreenInner({ user }: Props) {
  const { updateMe } = useUpdateMe();
  const { updateEmail } = useUpdateEmail();
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(new UiErrorState());
  const [name, setName] = useState(user.name ?? "");
  const [email, setEmail] = useState(user.email ?? "");
  const [search] = useSearchParams();
  const [birthDate, setBirthDate] = useState<DayValue>(birthDateToDay());
  const params = useParams();
  const redirect = search.get("r");
  const { t } = useTranslation();
  function redirectAfterSave() {
    window.location.href = redirect ? decode(redirect) : `/${params.lang}`;
  }

  function birthDateToDay(): Day | undefined {
    if (!user.birthDate) return;
    const bd = dayjs(user.birthDate);
    return {
      day: bd.date(),
      year: bd.year(),
      month: bd.month() + 1,
    };
  }

  function clearError(key: string): void {
    const es = errorState.clone();
    es.disableError(key);
    setErrorState(es);
  }

  function validate() {
    const es = new UiErrorState();
    const bd = getBirthDateAsDayjs();
    if (!name || !name.length) es.enableError("name");
    if (!email || !EmailValidator.validate(email)) es.enableError("email");
    if (!bd || !bd.isValid()) es.enableError("birthDate");
    if (bd && bd.isValid() && dayjs().diff(getBirthDateAsDayjs(), "year") < 18)
      es.enableError("birthDate");

    return es;
  }

  function getBirthDateAsDayjs(): Dayjs | undefined {
    if (!birthDate) return;
    return dayjs(`${birthDate.year}-${birthDate.month}-${birthDate.day}`);
  }

  async function save() {
    try {
      const es = validate();
      if (es.hasSomeError()) return setErrorState(es);

      setLoading(true);

      await updateMe({
        name,
        birthDate: getBirthDateAsDayjs()?.toISOString() as string,
      });

      await updateEmail(email);

      redirectAfterSave();
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout>
      <Header />
      <div className={"p-4 flex items-center flex-1"}>
        <div className={"w-full"}>
          <Card>
            <div className={"flex flex-col items-start"}>
              <Label label={t("userMainInfoInputScreen.nameLabel")} />
              <div className={"mt-2 w-full"}>
                <TextInput
                  errorString={
                    errorState.hasError("name")
                      ? t("userMainInfoInputScreen.youMustSpecifyAName")
                      : undefined
                  }
                  placeholder={t("userMainInfoInputScreen.yourName")}
                  value={name}
                  onChange={(v) => {
                    clearError("name");
                    setName(v);
                  }}
                />
              </div>
              <div className={"flex flex-col items-start w-full mt-4"}>
                <Label label={"Email"} />
                <div className={"mt-2 w-full"}>
                  <TextInput
                    type={"email"}
                    errorString={
                      errorState.hasError("email")
                        ? "Devi specificare un'email valida"
                        : undefined
                    }
                    placeholder={"La tua email"}
                    value={email}
                    onChange={(v) => {
                      clearError("email");
                      setEmail(v);
                    }}
                  />
                </div>
              </div>
              <div className={"flex flex-col items-start w-full mt-4"}>
                <Label label={t("userMainInfoInputScreen.birthDayLabel")} />
                <div className={"mt-2 w-full"}>
                  <DatePicker
                    calendarClassName={"text-[6.5px] md:text-[12px]"}
                    formatInputText={() => {
                      if (!birthDate) return "";
                      return getBirthDateAsDayjs()?.format("DD/MM/YYYY") ?? "";
                    }}
                    value={birthDate}
                    onChange={(v) => {
                      setBirthDate(v);
                      clearError("birthDate");
                    }}
                    inputPlaceholder={t(
                      "userMainInfoInputScreen.selectBirthDay"
                    )}
                    wrapperClassName={"w-full"}
                    inputClassName={[
                      errorState.hasError("birthDate")
                        ? "border-red-500"
                        : "border-gray-200",
                      "border placeholder-gray-400 text-base rounded-md h-10 min-w-[200px] p-2 w-full text-left text-md text-gray-500",
                    ].join(" ")}
                  />
                  {errorState.hasError("birthDate") ? (
                    <div className={"flex justify-center"}>
                      <span
                        className={
                          "text-red-600 text-sm text-center mt-2 flex-shrink flex-grow-0"
                        }
                      >
                        {t("userMainInfoInputScreen.youMustSpecifyABirthDay")}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={"mt-4 flex justify-center w-full"}>
                <PrimarySolidButton
                  loading={loading}
                  onClick={save}
                  label={t("userMainInfoInputScreen.confirm")}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Footer />
    </Layout>
  );
}

export function UserMainInfoInputScreen() {
  const { user } = useAuthCtx();
  if (!user) return null; //TODO should never happen, but if it happen show error
  return <UserMainInfoInputScreenInner user={user} />;
}
