import { useSearchParams } from "react-router-dom";
export enum OrderChannels {
  MobileApp = "MobileApp",
  Web = "Web",
}
export function useOrderChannel() {
  const [params] = useSearchParams();
  const channel = params.get("c");
  if (!channel) return OrderChannels.MobileApp;
  if (Object.values(OrderChannels).includes(channel as OrderChannels))
    return channel as OrderChannels;
  return OrderChannels.MobileApp;
}
