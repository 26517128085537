import { gql, useQuery } from "@apollo/client";
import { EventPRDTO } from "./EventPRDTO";

const GET_EVENT_PR = gql`
  query getEventPrs($eventId: String!) {
    getEventPrs(eventId: $eventId) {
      eventId
      userId
      userName
      userProfileImage {
        src
        thumbnail
      }
    }
  }
`;
type Response = {
  getEventPrs: EventPRDTO;
};

type Input = {
  eventId: string;
};
export function useGetEventPrs(eventId?: string) {
  const { data, loading } = useQuery<Response, Input>(GET_EVENT_PR, {
    variables: {
      eventId: eventId as string,
    },
    skip: !eventId,
  });

  return {
    eventPrs: (data?.getEventPrs ?? []) as EventPRDTO[],
    loading,
  };
}
