import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  noPadding?: boolean;
  className?: string;
}>;
export function SquaredCard(props: Props) {
  return (
    <div
      className={[
        "border flex flex-col border-slate-200 rounded-lg bg-white",
        props.noPadding ? "" : "p-4",
        props.className ?? "",
      ].join(" ")}
    >
      {props.children ?? null}
    </div>
  );
}
