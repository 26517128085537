// interface Gtag {
//     (command: 'config', targetId: string, config?: ControlParams | EventParams | CustomParams): void;
//     (command: 'set', config: CustomParams): void;
//     (command: 'js', config: Date): void;
//     (command: 'event', eventName: EventNames | string, eventParams?: ControlParams |  EventParams | CustomParams): void;
//   }

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const safeGtag: Gtag.Gtag = (c: any, t: any, d?: any) => {
  if (typeof gtag === "undefined") {
    console.warn("no gtag detected");
    if (d?.event_callback) {
      d.event_callback();
    }
    return;
  }

  return gtag(c, t, d);
};
