import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  onClick?: () => void;
  className?: string;
}>;
export function Card(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className={[
        "rounded-3xl bg-white p-6 shadow-lg shadow-slate-300/60",
        props.onClick ? "cursor-pointer" : "",
        props.className ?? "",
      ].join(" ")}
    >
      {props.children ?? null}
    </div>
  );
}
