import _ from "lodash";

export class UiErrorState {
  private errors: Record<string, boolean> = {};

  public hasError(key: string) {
    return this.errors[key];
  }

  public enableError(key: string) {
    this.errors[key] = true;
  }

  public disableError(key: string) {
    this.errors[key] = false;
  }

  public clearAll(): void {
    this.errors = {};
  }

  public hasSomeError(): boolean {
    return !!_.find(this.errors, (v) => v);
  }

  public clone() {
    return _.cloneDeep(this);
  }
}
