import { EventDTO } from "../Event/EventDTO";
import { RateWithQty } from "./RateWithQty";
import { PaymentMethodDto, usePaymentMethods } from "./usePaymentMethods";
import { EventOrderDTO } from "./EventOrderDTO";
import { EventPRDTO } from "./EventPRDTO";
import { loadStripe } from "@stripe/stripe-js";
import { useCreateStripePaymentIntent } from "./useCreateStripePaymentIntent";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useCheckoutSessionCtx } from "./useCheckoutSessionCtx";
import { useTranslation } from "react-i18next";
import { ReserveStockStatus, useReserveRates } from "./useReserveRates";
import { useCreateDraftOrder } from "./useCreateDraftOrder";
import { NewPmCallback } from "./NewPmCallback";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOrderChannel } from "./useOrderChannel";
import { safeGtag } from "../Analytics/safeGtag";
import { AddPmForm, PmIdBagResponse } from "./AddPmForm";
import { FullScreenLoading } from "../UI/FullScreenLoading";
import { Layout } from "../UI/Layout";
import { Header } from "../UI/Header";
import { ErrorNotificationToast } from "../UI/ErrorNotificationToast";
import { CheckoutSessionTimer } from "./CheckoutSessionTimer";
import { OrderSummary } from "./OrderSummary";
import { SelectPRSection } from "./SelectPRSection";
import { RadioGroup } from "@headlessui/react";
import { PaymentMethodComponent } from "./PaymentMethodComponent";
import { PrimarySolidButton } from "../UI/PrimarySolidButton";
import { Footer } from "../UI/Footer";
import { safelyParseJson } from "./Helpers/safelyParseJson";
import { useEventById } from "../Event/useEventById";
import { useEventRatesByIds } from "./useEventRatesByIds";
import { useGetEventPrs } from "./useGetEventPrs";
import { CheckoutSessionCtxProvider } from "./CheckoutSessionCtxProvider";
import { decode } from "base-64";

type Props = {
  event: EventDTO;
  eventRates: RateWithQty[];
  paymentMethods: PaymentMethodDto[];
  order: EventOrderDTO;
  eventPrs: EventPRDTO[];
  setOrder: (order: EventOrderDTO) => void;
};
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);
function CheckoutComponentInner({
  event,
  eventRates,
  paymentMethods,
  order,
  eventPrs,
  setOrder,
}: Props) {
  const { createStripePaymentIntent } = useCreateStripePaymentIntent();
  const [showMissingPmToast, setShowMissingPmToast] = useState(false);
  const [showMissingPrToast, setShowMissingPrToast] = useState(false);

  const paymentMethodRef = useRef<HTMLDivElement>(null);
  const stripe = useStripe();

  const { sessionId, deleteSession, setSessionId } = useCheckoutSessionCtx();
  const [firstLoaded, setFirstLoaded] = useState(false);
  const { t } = useTranslation();

  const [paying, setPaying] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [loadingReserve, setLoadingReserve] = useState(true);
  const [reserveStatus, setReserveStatus] = useState<ReserveStockStatus>(
    ReserveStockStatus.NotEnoughStock
  );

  const [selectedPr, setSelectedPr] = useState<string>();

  const [firstReservedRates, setFirstReserverRates] = useState(false);

  const { createDraftOrder, loading: creatingDraftOrder } =
    useCreateDraftOrder();

  async function updatePr(prId: string) {
    const res = await createDraftOrder(
      eventId,
      eventRates.map((r) => {
        return {
          id: r.rate.id,
          quantity: r.qty,
        };
      }),
      sessionId,
      channel,
      prId
    );
    setOrder(res.order);
    if (res.hasNewSession) setSessionId(res.newSessionId);
  }

  const [cbBag, setCbBag] = useState<{
    newPm: NewPmCallback;
  }>();

  const { reserveRates } = useReserveRates();
  const eventId = event.id;
  useEffect(() => {
    (async function reserve() {
      try {
        console.log("Reserving rates");
        setLoadingReserve(true);
        const status = await reserveRates(
          eventId,
          eventRates.map((r) => ({
            id: r.rate.id,
            quantity: r.qty,
          })),
          sessionId
        );
        setReserveStatus(status);
        setFirstReserverRates(true);
      } finally {
        setLoadingReserve(false);
      }
    })();
  }, [reserveRates, eventRates, eventId, sessionId]);

  const [selectedPm, setSelectedPm] = useState<string>("new-pm");

  const navigate = useNavigate();

  const channel = useOrderChannel();

  const orderId = order.id;

  const setNewPmCallback = useCallback((cb: NewPmCallback) => {
    setCbBag({
      newPm: cb,
    });
  }, []);

  const selectPm = useCallback((pmId: string) => {
    safeGtag("event", "PaymentMethodSelected");
    setSelectedPm(pmId);
  }, []);
  useEffect(() => {
    (async () => {
      if (firstLoaded) return;

      setFirstLoaded(true);
      if (paymentMethods.length > 1 && selectedPm === "new-pm") {
        console.log("Selecting default pm");
        selectPm(paymentMethods[0].id);
      }
    })();
  }, [paymentMethods, selectPm, selectedPm, firstLoaded]);

  async function getPmId(): Promise<PmIdBagResponse> {
    if (!selectedPm)
      return {
        ok: false,
        error: "No payment method selected",
      };
    if (selectedPm === "new-pm") {
      if (!cbBag) throw new Error("No callback bag");
      return await cbBag.newPm();
    }
    return {
      ok: true,
      pmId: selectedPm,
    };
  }

  async function pay() {
    if (!stripe) return;
    if (!selectedPm) {
      setShowMissingPmToast(true);
      paymentMethodRef.current?.scrollIntoView();
      return;
    }

    if (!selectedPr && eventPrs.length > 0) {
      setShowMissingPrToast(true);
      return;
    }

    try {
      setPaying(true);
      const { ok, pmId } = await getPmId();
      if (!ok) return setShowErrorToast(true);
      setSelectedPm(pmId as string);
      safeGtag("event", "PayPressed");
      const { paymentIntentSecret, paymentMethodId } =
        await createStripePaymentIntent(
          eventId,
          pmId as string,
          orderId,
          sessionId
        );
      const { error } = await stripe.confirmCardPayment(paymentIntentSecret, {
        payment_method: paymentMethodId,
      });
      if (error) {
        setShowErrorToast(true);
      } else {
        deleteSession();
        navigate(`/thankyou?oid=${order.id}&c=${channel}`);
      }
    } finally {
      setShowErrorToast(true);
      setPaying(false);
    }
  }

  if (!event || !eventRates.length) return <div>Event not found</div>; //TODO gestire meglio

  if (loadingReserve && !firstReservedRates) return <FullScreenLoading />;

  if (reserveStatus === ReserveStockStatus.NotEnoughStock) {
    return (
      <Layout>
        <div className={"flex-1 flex flex-col p-4"}>
          <h2 className={"font-bold text-gray-900"}>
            {t("checkout.soldOutTitle")}
          </h2>
          <div className={"h-40 mt-4 mx-auto rounded-md overflow-hidden"}>
            <img className={"h-full"} src={"/sad-gif.webp"} alt={"sad"} />
          </div>
          <p className={"text-gray-600 mt-4"}>{t("checkout.soldOutExpl")}</p>
        </div>
      </Layout>
    );
  }

  async function selectPr(prId: string) {
    setSelectedPr(prId);
    await updatePr(prId);
  }

  return (
    <Layout>
      <Header />
      <ErrorNotificationToast
        show={showMissingPrToast}
        durationInMs={4000}
        close={() => setShowMissingPrToast(false)}
        description={t("checkout.pr.missingDesc")}
        title={t("checkout.pr.missingTitle")}
      />
      <ErrorNotificationToast
        show={showMissingPmToast}
        durationInMs={4000}
        close={() => setShowMissingPmToast(false)}
        description={
          paymentMethods.length > 0
            ? t("checkout.missingPmErrorMessage")
            : t("checkout.addPaymentMethodErrorMessage")
        }
        title={
          paymentMethods.length > 0
            ? t("checkout.missingPmErrorTitle")
            : t("checkout.addPaymentMethodErrorTitle")
        }
      />
      <ErrorNotificationToast
        show={showErrorToast}
        durationInMs={4000}
        close={() => setShowErrorToast(false)}
        description={t("checkout.somethingWentWrongDescription")}
        title={t("checkout.somethingWentWrongTitle")}
      />
      <div className={"flex-1 flex flex-col p-4"}>
        <h2 className={"mb-4 text-gray-600 font-bold"}>
          {t("orderSummary.title")}
        </h2>
        <div className={"mb-4"}>
          <CheckoutSessionTimer />
        </div>
        <OrderSummary order={order} />
        <SelectPRSection onSelect={selectPr} prs={eventPrs} />
        <h2 className={["mb-4 mt-6 font-bold text-gray-600"].join(" ")}>
          {t("checkout.paymentMethodTitle")}
        </h2>
        <div>
          <RadioGroup
            className={"space-y-4 flex flex-col relative"}
            value={selectedPm}
            onChange={selectPm}
          >
            <RadioGroup.Option value={"new-pm"}>
              {({ checked }) => (
                <Elements stripe={stripePromise}>
                  <AddPmForm
                    setNewPmCallback={setNewPmCallback}
                    checked={checked}
                  />
                </Elements>
              )}
            </RadioGroup.Option>
            {paymentMethods.map((pm) => {
              return (
                <div key={pm.id}>
                  <RadioGroup.Option value={pm.id}>
                    {({ checked }) => (
                      <PaymentMethodComponent
                        showCheckBox
                        pm={pm}
                        checked={checked}
                      />
                    )}
                  </RadioGroup.Option>
                </div>
              );
            })}{" "}
          </RadioGroup>
        </div>
        <div
          className={
            "mt-auto w-full flex flex-col items-stretch sticky bottom-0 py-3"
          }
        >
          <PrimarySolidButton
            disabled={creatingDraftOrder}
            loading={paying}
            onClick={pay}
            label={t("checkout.pay")}
          />
        </div>
      </div>
      <Footer />
    </Layout>
  );
}
function createStripePromise(stripeAccountId: string) {
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string, {
    stripeAccount: stripeAccountId,
  });
}

type CheckoutData =
  | {
      eventId: string;
      eventRateId: string;
      quantity?: number;
      prId?: string;
    }
  | {
      eventId: string;
      rates: { rateId: string; qty: number }[];
      prId?: string;
    };

function parseData(data: string):
  | {
      rates: {
        rateId: string;
        qty: number;
      }[];
      eventId: string;
      prId?: string;
    }
  | undefined {
  const d = safelyParseJson<CheckoutData>(data);
  if (!d || !d.eventId) return;

  if ("eventRateId" in d) {
    return {
      eventId: d.eventId,
      rates: [
        {
          rateId: d.eventRateId,
          qty: d.quantity ?? 1,
        },
      ],
      prId: d.prId,
    };
  } else if ("rates" in d) {
    return {
      eventId: d.eventId,
      rates: d.rates,
      prId: d.prId,
    };
  }
}

function CheckoutScreenFetchWrapper() {
  const [searchParams] = useSearchParams();
  const [firstLoading, setFirstLoading] = useState(true);
  const [order, setOrder] = useState<EventOrderDTO>();
  const data = searchParams.get("d");
  const [eventId, setEventId] = useState<string>();
  const [rateIds, setRateIds] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<Record<string, number>>({});
  const { setSessionId, sessionId } = useCheckoutSessionCtx();
  const { createDraftOrder, loading: creatingDraftOrder } =
    useCreateDraftOrder();
  const { event, loading: loadingEvent } = useEventById(eventId);
  const { rates, loading: loadingEventRate } = useEventRatesByIds(
    eventId,
    rateIds
  );
  const { paymentMethods, loading: loadingPaymentMethods } =
    usePaymentMethods();
  const channel = useOrderChannel();
  const { eventPrs, loading: loadingPrs } = useGetEventPrs(eventId);
  useEffect(() => {
    const realData = parseData(decode(data ?? ""));
    if (!realData) return setFirstLoading(false);
    (async function initOrder() {
      if (!order) {
        const res = await createDraftOrder(
          realData.eventId,
          realData.rates.map((r) => {
            return {
              id: r.rateId,
              quantity: r.qty,
            };
          }),
          sessionId,
          channel,
          realData.prId
        );
        setOrder(res.order);
        if (res.hasNewSession) setSessionId(res.newSessionId);
      }
      setEventId(realData.eventId);
      setQuantities(
        realData.rates.reduce((acc, r) => {
          acc[r.rateId] = r.qty;
          return acc;
        }, {} as Record<string, number>)
      );
      setRateIds(realData.rates.map((r) => r.rateId));
      setFirstLoading(false);
    })();
  }, [data, createDraftOrder, order, sessionId, setSessionId, channel]);

  const realLoading =
    loadingEvent ||
    loadingEventRate ||
    loadingPaymentMethods ||
    firstLoading ||
    creatingDraftOrder ||
    loadingPrs;

  if (realLoading) return <FullScreenLoading />;
  if (!event || !order) return <p>Missing entities</p>; //TODO show 404
  if (!event.creatorStripeAccountId)
    return <p>Missing creator stripe account</p>; //TODO show 404
  return (
    <Elements stripe={createStripePromise(event.creatorStripeAccountId)}>
      <CheckoutComponentInner
        setOrder={setOrder}
        eventPrs={eventPrs}
        order={order}
        event={event}
        eventRates={rates.map((r) => {
          const qty = quantities[r.id];
          if (!qty) throw new Error(`Missing quantity for rate ${r.id}`); //Should never happen
          return {
            rate: r,
            qty,
          };
        })}
        paymentMethods={paymentMethods}
      />
    </Elements>
  );
}

export function CheckoutScreen() {
  return (
    <CheckoutSessionCtxProvider>
      <CheckoutScreenFetchWrapper />
    </CheckoutSessionCtxProvider>
  );
}
