import { useCallback, useState } from "react";
import { useAddPaymentMethod } from "./useAddPaymentMethod";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";

export function useCreatePaymentMethod() {
  const [creating, setCreating] = useState(false);
  const { addPaymentMethod } = useAddPaymentMethod();

  const createPm = useCallback(
    async (stripe: Stripe, elements: StripeElements): Promise<string> => {
      if (!stripe || !elements) throw new Error("Stripe not loaded");
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) throw new Error("Card element not loaded");
      try {
        setCreating(true);
        const res = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        if (res.error) throw new Error(res.error.message);
        return await addPaymentMethod(res.paymentMethod.id);
      } finally {
        setCreating(false);
      }
    },
    [addPaymentMethod]
  );

  return {
    createPm,
    creating,
  };
}
