import { gql, useMutation } from "@apollo/client";
import { EventRateToPayInput } from "./EventRateToPayInput";
import { useCallback } from "react";

const RESERVE_MUTATION = gql`
  mutation reserveRates(
    $eventRatesToPay: [EventRateToPayInput!]!
    $eventId: String!
    $sessionId: String!
  ) {
    reserveRates(
      eventRatesToPay: $eventRatesToPay
      eventId: $eventId
      sessionId: $sessionId
    ) {
      status
    }
  }
`;

type Response = {
  reserveRates: {
    status: ReserveStockStatus;
  };
};

type Input = {
  eventRatesToPay: EventRateToPayInput[];
  eventId: string;
  sessionId: string;
};

export enum ReserveStockStatus {
  NotEnoughStock = "NotEnoughStock",
  Success = "Success",
}

export function useReserveRates() {
  const [reserveRatesMutation, { loading }] = useMutation<Response, Input>(
    RESERVE_MUTATION
  );

  const reserveRates = useCallback(
    async (
      eventId: string,
      rates: EventRateToPayInput[],
      sessionId: string
    ): Promise<ReserveStockStatus> => {
      const { data } = await reserveRatesMutation({
        variables: {
          eventRatesToPay: rates,
          eventId,
          sessionId,
        },
      });
      const res = data?.reserveRates?.status;
      if (!res)
        throw new Error(
          `No status returned for reserve rates of event ${eventId}`
        );
      return res;
    },
    [reserveRatesMutation]
  );

  return {
    loading,
    reserveRates,
  };
}
